import React, { useState } from "react"
import { CheckoutWrapperWhiteBG, getCheckoutSteps } from "./shared"
import { CheckoutCartItemBox } from "./CheckoutCartItemBox"
import { getCheckoutError, setCheckoutProgress } from "../../service/checkout-progress"
import { ProgressStepBar } from "../../locust/components/ProgressStepBar"
import SpacerV from "../../locust/sections/SpacerV"
import { SectionTitle } from "./CheckoutSectionTitle"
import X from "../../locust/composables/X"
import { css } from "glamor"
import XY from "../../locust/composables/XY"
import { SpacerVRsp } from "../../locust/composables/SpacerVRsp"
import { Link } from "gatsby"
import { CartItemTitle } from "./CartItemTitle"
import { FACEBOOK_BLACK, FACEBOOK_SKYBLUE } from "../../assets/colors/Colors"
import { CircularProgress } from "@mui/material"
import NoticeAndLink from "../shared/NoticeAndLink"
import { getRandomString } from "../../locust/composables/Shared"
import { Page, to } from "../shared/SiteLinks"

// [TO-DO] checkout -> capture the sale information from a timeout and submit a high-severity form to yourself and contact
// them and ask them if they would like to try again. 

export default( props : { 
    setCheckoutProgress : ( ) => void,
    checkoutProgress : any,
}) => {
    const checkoutProgress : any = props.checkoutProgress
    

    const [ data, setData ] = React.useState<any>(false)

    React.useEffect(()=>{
        setData(getCheckoutError())
    },[])


    if( data === false ) return (
        <div style={{ width : '100%', height : '70vh'}}>
            <XY>
                <CircularProgress style={{ color : FACEBOOK_BLACK, marginTop : 35 }}/>
            </XY>
        </div>
    )

    

    const isError : boolean = data.response === 'error'

    return (
        <>  
            <SpacerV h={50}/>
            <X>
                <SectionTitle style={{ width : '100%', marginTop : -20}}>
                    <p style={{ fontSize : 36, fontWeight : 800 }}>
                        { isError ? 'Unknown Error' : 'Transaction Timeout'}
                    </p>
                </SectionTitle>
            </X>    
            
            <NoticeAndLink
                onClick={()=>{ 
                    if(!isError){
                        props.setCheckoutProgress()

                    }
                 }}
                
                href={ isError ? to(Page.HOME) : to(Page.CHECKOUT) }
                value={ isError ? "Return to Store" : "Try Different Card?"}
            >
                { isError 
                    ? <>Your purchase attempted timeout out after 60 seconds.</> 
                    : <div style={{ width : 300 }}>Please try again later</div>
                }


                
            </NoticeAndLink>
            
        </>
    )
}
