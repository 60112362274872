import { Button, CircularProgress, FormControl, FormControlLabel, InputLabel, MenuItem, Radio, RadioGroup, Select } from '@mui/material'
import { css } from 'glamor'
import React, { useState } from 'react'
import { FACEBOOK_BLACK, FACEBOOK_BLUE } from '../../assets/colors/Colors'
import { getFontRaleway } from '../../assets/fonts/Raleway'
import { BreadCrumbLink } from '../../locust/components/BreadCrumb'
import { RadioBox, RadioBoxListItem } from '../../locust/components/Payment'
import { Popup, PopupSlide } from '../../locust/components/PopupSlide'
import { getConfigDefaults, GlobalConfig, validateConfig } from '../../locust/composables/Layout'
import LinkDelayed from '../../locust/composables/LinkDelayed'
import { getEmpty } from '../../locust/composables/Shared'
import { SpacerVRsp } from '../../locust/composables/SpacerVRsp'
import X from '../../locust/composables/X'
import XY from '../../locust/composables/XY'
import Y from '../../locust/composables/Y'
import SpacerV from '../../locust/sections/SpacerV'
import { getIcyInferno } from '../../products/data/icy-inferno/v1/Data'
import { getShoppingCart } from '../../service/shopping-cart'
import OrdersInfoBox from '../orders/OrdersInfoBox'
import OrderText from '../shared/EntryItemText'
import ProductAddedContainer, { ProductEdit, ValueEditorII } from '../products/ProductAdded'
import ProductAddedInfo from '../products/ProductAddedInfo'
import { CartItemTitle } from './CartItemTitle'
import CheckoutButtonBig from './CheckoutButtonBig'
import { getTestPromotion } from './TestComponents'
import { TitleAndPrice } from './TitleAndPrice'
import { ENABLE_ERROR_LOGGING } from '../../settings/project-settings'

const MODE_HAS_QUANTITY = false

export const CartItem = ( props : { 
    img : string, 
    item : any, 
    onClickRemove : () => void,
    quantity : number, 
    setQuantity : ( value : number ) => void, 
    protection ?: any, 
    setProtectionPlan : ( value : string ) => void, 
    setProtectionPlanQuantity : ( value : number ) => void,
    subscription ?: any,
    setSubscription ?: ( value : string) => void  
    setSubscriptionPlanQuantity : ( value : number ) => void,
    setPromotion ?: ( value : any ) => void
    updatedShoppingCartLength : () => void,
    children ?: any 
  }) => {


    
    const controlsSizeH : number = 170
    const controlsSizeV : number = 30
    const imgSize : number = 125
    const imgSizeBP : number = 125
    const config : GlobalConfig = validateConfig()
    const onBMBreakpoint = config.onMedia(991)
    const onSubbarBreakpoint = config.onMedia(1100)
    const onImgBreakpoint = config.onMedia(771)

    const [quantity, setQuantity] = useState( props.quantity )
    const [protectionPlan, setProtectionPlan] = useState<any|undefined>( props.protection?.plan  )
    const [protectionPlanQuantity, setProtectionPlanQuantity] = useState( props.protection ? props.protection[props.protection.plan].quantity : 0 )
    const [subscriptionPlanQuantity, setSubscriptionPlanQuantity] = useState( props?.subscription ? props.subscription[props.subscription.plan].quantity : 0 )
    const [subscription, setSubscription] = useState( props?.subscription ? props.subscription.plan : 0 )
    const [promoCode, setPromoCode] = useState<string|React.ReactNode>( 'FIRSTYEARFREE')
    //const [promoCode, setPromoCode] = useState<string|React.ReactNode>( props?.promotion ? props.promotion : 0 'FIRSTYEARFREE')

    const setQuantityBoth = ( value : number ) => {
        setQuantity( value )
        props.setQuantity( value )
    }

    const setProtectionPlanBoth = ( value : string ) => {
        setProtectionPlan( value )
        if( props.protection ){
            let hasPlanChange = protectionPlan!==value
            if( hasPlanChange ) props.setProtectionPlan( value )
        } 
    }

    const setProtectionPlanQuantityBoth = ( value : number ) => {
        setProtectionPlanQuantity( value )
        props.setProtectionPlanQuantity( value )
    }

    const setSubscriptionBoth = ( value : string ) => {
        setSubscription( value )
        if( props.subscription ){
            let hasPlanChange = subscription!==value
            if( hasPlanChange && props.setSubscription ) 
                props.setSubscription( value )
        } 
    }

    const setSubscriptionPlanQuantityBoth = ( value : number ) => {
        setSubscriptionPlanQuantity( value )
        props.setSubscriptionPlanQuantity( value )
    }

    const cart : any = getShoppingCart()

    const [orderData, setOrderData ] = React.useState({ product : cart[Object.keys(props.item)[0]] })

    const CART_HAS_PROTECTION_PLAN_AVAILABLE = JSON.stringify(cart)!=="{}"  
        && Object.keys( cart ).map( x => cart[x] ).filter( x => x.type === 'protection' ).length > 0 

    if( ENABLE_ERROR_LOGGING ) console.log(
        Object.keys( cart ).map( x => cart[x] ).filter( x => x['protection'] !== undefined  )
    )


  return (
    <div {...css({ width : '100%', height : 'auto' })}>
        
        

        <ProductEdit 
            orderData={orderData}
            setOrderData={setOrderData}
            buttons={[
                <div style={{ cursor : 'pointer', width : 100, height: 20, marginLeft : 10, marginRight : 10 }}onClick={()=>{
            
                    props.onClickRemove()
                    props.updatedShoppingCartLength()
                }}>
                     <BreadCrumbLink>
                            Remove
                    </BreadCrumbLink>
                    
                </div>
            ]}
        />
        


        

        

        
        { true && <>
            <SpacerV h={50}/>
            <TitleAndPrice title={"Use Promo Code"}/>
            <SpacerV h={14}/>
            <RadioBox 
                style={{ width : 'calc(100% - 15px) ', marginLeft : 0, marginTop : 0, paddingTop : 0, height : 50, }}  
                onClick={()=>{  
                    
                }}
            >   
                <div style={{ verticalAlign : 'top', display : 'inline-block', height: 50, width : `calc(100% - ${80}px)`}}>
                    
                    { typeof promoCode === 'string' && 
                        <input onChange={( event )=>{ setPromoCode(event.target.value) }} value={promoCode} style={{ paddingTop : 2,width : '100%', backgroundColor : 'transparent', height : 48, outline : 'none', marginLeft : -0, border : 0, marginTop : -1, ...getFontRaleway(), fontSize : 16  }} type="text"></input>
                    }

                    { typeof promoCode !== 'string' && 
                        <>{promoCode}</>
                    }

                </div>
                <div onClick={()=>{

                    setPromoCode(
                        <Y>     
                            <CircularProgress style={{ marginLeft : 50, color : FACEBOOK_BLUE }} size={20}/>
                        </Y>
                    )
                    setTimeout(()=>{
                        setPromoCode('Success!')

                        const promotion = getTestPromotion()
                        if( promotion.type === 'subscription' && props.subscription ){
                            Object.keys(props.subscription).forEach(( keyValue : string )=>{
                                if( keyValue === 'plan'     ) return 
                                if( keyValue === 'title'    ) return
                                if( keyValue === 'required' ) return
                                
                                const subscriptionId : string = props.subscription[keyValue].id 
                                const promotionSubscriptionId : string = promotion.applyId
                                const applicablePromotion : boolean = subscriptionId === promotionSubscriptionId
                                if(!applicablePromotion) return 

                                if( props.setPromotion )
                                    props.setPromotion( promotion )
                                
                                    setSubscription('promotion')
                                
                            })
                        }
                    },2000)

                }} style={{ verticalAlign : 'top',  display : 'inline-block',height: 50,  width : 80, backgroundColor :''  }}>
                    
                    <XY>
                        <CartItemTitle style={{ color : FACEBOOK_BLACK, [':hover']:{ color : '#1877F2'} }}>Apply</CartItemTitle>
                    </XY>
                    
                </div>
            </RadioBox>
        
        
        </>}
        
        { props.subscription && <> 

                <SpacerV h={30}/>
                <TitleAndPrice price={props.subscription[props.subscription.plan].price} title={`${props.subscription.title}${ props.subscription.required ? ' (Required)' : ''}`}/>

                <SpacerV h={20}/>
                <RadioGroup 
                    value={ subscription }
                    onChange={( event )=>{
                        const subscriptionPlan = event.target.value.toString()
                        setSubscriptionBoth(subscriptionPlan)         
                    }}
                >
                  {
                      Object.keys(props.subscription).map(( keyValue, index : number  )=>{
                          if( keyValue === 'plan'     ) return
                          if( keyValue === 'required' ) return
                          if( keyValue === 'title' ) return
                          const subscriptionItem = props.subscription[keyValue]
                          const maxQuantity : number = props.quantity
                          return (
                              <div key={index}>
                                    <RadioBox 
                                        style={{ width : 'calc(100% - 15px) ', marginLeft : 0, height : 'auto' }}  
                                        onClick={()=>{  
                                            setSubscriptionBoth(keyValue)   
                                        }}
                                    >
                                        <FormControlLabel value={ keyValue } control={ <Radio/> } label={subscriptionItem.name} />
                                        <SpacerV h={8}/>


                                        { 
                                            ( MODE_HAS_QUANTITY && 
                                              props.subscription.plan === keyValue && 
                                              props.subscription[props.subscription.plan].quantity > 0
                                            ) && <>
                                                
                                                <FormControl fullWidth size="small" style={{ width : 130, position : 'absolute', top : 0, marginLeft : `calc(100% - ${139}px)`, marginTop : 2  }}>
                                                    <InputLabel id="demo-simple-select-label">Qty</InputLabel>
                                                    <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={Math.min(Math.max(subscriptionPlanQuantity, 1), maxQuantity ).toString()}
                                                    label="Qty "
                                                    onChange={( event )=>{
                                                        const newValue : number = event.target.value as unknown as number
                                                        setSubscriptionPlanQuantityBoth( newValue )  
                                                    }}
                                                    >

                                                    
                                                    {  getEmpty(maxQuantity).map(( empty : any, index : number )=>{
                                                        const value : number = index + 1
                                                        return <MenuItem value={value.toString()}>{value}</MenuItem>
                                                    }) }
                                                    
                                                    

                                                    </Select>
                                                </FormControl>
                                            </>
                                        }


                                  </RadioBox>

                                  { 
                                    
                                      (subscriptionItem.promotion && subscription === keyValue ) && <>
                                          
                                          <RadioBox 
                                              style={{ width : 'calc(100% - 15px) ', marginLeft : 0, height : 'auto' }}  
                                              onClick={()=>{  
                                                  //alert(444)
                                                  //setProtectionPlanBoth( keyValue )  
                                              }}
                                          >
                                              <FormControlLabel value={ keyValue } control={ <Radio/> } label={subscriptionItem.promotion.name} />
                                              <SpacerV h={8}/>
                                          </RadioBox>
                                      
                                      
                                      </>
                                  }
                              </div>
                          )


                      })
                  }
                  
                </RadioGroup>
                <SpacerV h={30}/>
                
                

            </>
        }

        { props.protection && <>
            
            <TitleAndPrice title={props.protection.title} price={props.protection[protectionPlan].price * props.protection[protectionPlan].quantity}/>
            <SpacerV h={20}/>

            <RadioGroup 
                value={ protectionPlan }
                onChange={( event )=>{
                    const protectionPlan = event.target.value.toString()
                    setProtectionPlanBoth(protectionPlan)         
                }}
                >   
                { Object.keys(props.protection).map(( keyValue, index : number  )=>{
                    if( keyValue === 'plan' ) return
                    if( keyValue === 'title' ) return
                    const protectionPlanItem = props.protection[keyValue]
                    const maxQuantity : number = props.quantity
                    
                    return (
                        <RadioBox key={index} style={{ width : 'calc(100% - 15px) ', marginLeft : 0, height : 'auto' }}  onClick={()=>{  setProtectionPlanBoth( keyValue )  }}>
                            <FormControlLabel value={ keyValue } control={ <Radio/> } label={protectionPlanItem.name} />
                            <SpacerV h={8}/>
                            {   protectionPlanItem.terms && 
                                <> 
                                    { 
                                        Object.keys(protectionPlanItem.terms).map(( planKey : string, index : number  )=>{

                                            return <RadioBoxListItem key={index}>{ protectionPlanItem.terms[planKey] }</RadioBoxListItem> 

                                        })
                                        
                                    }
                                 
                                    <SpacerV h={20}/>

                                    { 
                                        ( MODE_HAS_QUANTITY && props.protection[props.protection.plan].quantity > 0) && <>
                                            
                                            <FormControl fullWidth size="small" style={{ width : 125, position : 'absolute', top : 0, marginLeft : `calc(100% - ${139}px)`, marginTop : 6  }}>
                                                <InputLabel id="demo-simple-select-label">Qty</InputLabel>
                                                <Select
                                                  labelId="demo-simple-select-label"
                                                  id="demo-simple-select"
                                                  value={Math.min(Math.max(protectionPlanQuantity, 1), maxQuantity ).toString()}
                                                  label="Qty "
                                                  onChange={( event )=>{
                                                      const newValue : number = event.target.value as unknown as number
                                                      setProtectionPlanQuantityBoth( newValue )  
                                                  }}
                                                >

                                                  
                                                  {  getEmpty(maxQuantity).map(( empty : any, index : number )=>{
                                                      const value : number = index + 1
                                                      return <MenuItem key={index} value={value.toString()}>{value}</MenuItem>
                                                  }) }
                                                  
                                                  

                                                </Select>
                                            </FormControl>
                                        </>
                                    }
                                    
                                    
                                    
                                </>
                                
                                
                            }
                            
                            
                        </RadioBox> 
                    )

                })}

              </RadioGroup>

              


        </>}

        
    </div>
    
  )
}


const ProductDisplayV1 = ( props : {
    onClickRemove : () => void,
    setQuantityBoth : ( value : number ) => void,
    quantity : number, 
}) => {

    
    return (
        <>

            <X style={{ justifyContent: 'space-between' }}>
            { MODE_HAS_QUANTITY && 
                    <>
                        <FormControl fullWidth size="small" style={{ width : 120 }}>
                            <InputLabel id="demo-simple-select-label">Qty</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={props.quantity.toString()}
                                label="Qty "
                                onChange={( event )=>{
                                    const newValue : number = event.target.value as unknown as number
                                    props.setQuantityBoth(newValue)
                                }}
                            >
                            { getEmpty(9).map(( empty : any, index : number )=>{
                                const value : number = index + 1
                                return <MenuItem key={index} value={value.toString()}>{value}</MenuItem>
                            }) }
                            </Select>
                        </FormControl>
                    </>
                }
                
                <CheckoutButtonBig 
                    onClick={props.onClickRemove}
                    style={{
                        width : 140, 
                        border : '1px solid rgba(0,0,0,0.5)',
                        boxShadow : '',
                        height : 48,
                        backgroundColor: 'white',
                        marginLeft : MODE_HAS_QUANTITY ? 10 : 0, 
                    }}
                >
                        <p style={{ color : FACEBOOK_BLACK }}>Remove</p>          
                </CheckoutButtonBig>
              



                
            </X>
        </>
    )
}


