//[TO-DO] SERACH AND REMOVE : nathanneuhaus@protonmail.com
import { css } from 'glamor';
import React, { useState } from 'react'
import { auth, isLoggedIn } from '../../../firebase-config';
import CheckoutButtonBig from '../../client-ui/checkout/CheckoutButtonBig';
import { SectionTitle } from '../../client-ui/checkout/CheckoutSectionTitle';
import CheckoutStepOne from '../../client-ui/checkout/CheckoutStepOne';
import { CheckoutWrapperWhiteBG, getCheckoutSteps, getSubtotal, getTax, getTotal, LeftFrame, RightFrame } from '../../client-ui/checkout/shared';
import { SummaryItem } from '../../client-ui/checkout/SummaryItem';
import { getTestShippingOptions } from '../../client-ui/checkout/TestComponents';
import { LineDivider } from '../../locust/components/LineDivider';
import { ProgressStepBar } from '../../locust/components/ProgressStepBar';
import { getConfigDefaults, GlobalConfig, LayoutFS, validateConfig } from '../../locust/composables/Layout';
import { getRandomString } from '../../locust/composables/Shared';
import SpacerV from '../../locust/sections/SpacerV';
import { getCheckoutProgress, setCheckoutProgress } from '../../service/checkout-progress';
import { getShoppingCart, getShoppingCartLength } from '../../service/shopping-cart';
import StripeBadge from '../../assets/stripe-power-logo.svg'
import CheckoutStepTwo from '../../client-ui/checkout/CheckoutStepTwo';
import CheckoutStepThree from '../../client-ui/checkout/CheckoutStepThree';
import CheckoutStepFour from '../../client-ui/checkout/CheckoutStepFour';
import { FACEBOOK_BLACK, FACEBOOK_SKYBLUE } from '../../assets/colors/Colors';
import CobraLayout from '../../client-ui/shared/LayoutCobra';
import CheckoutStepFive from '../../client-ui/checkout/CheckoutStepFive';
import CheckoutStepSix from '../../client-ui/checkout/CheckoutStepSix';
import NoticeAndLink from '../../client-ui/shared/NoticeAndLink';
import AuthManager from '../../locust/composables/AuthManager';
import { to, Page } from '../../client-ui/shared/SiteLinks';
import CheckoutStepTimeout from '../../client-ui/checkout/CheckoutStepTimeout';
import { ToastContainer } from 'react-toastify';
import { ENABLE_ERROR_LOGGING } from '../../settings/project-settings';



const breadcrumb = [
    { value : 'Home', url : to(Page.HOME) },
    { value : 'Products', url : `/checkout` },
 ]

const PageContainer = () => {
    
    const [loggedIn, setLoggedIn ] = React.useState(false)
    const [token, setToken ] = React.useState<string|undefined>()
    const [shoppingCartLength, setShoppingCartLength ] = React.useState<number>( getShoppingCartLength())
    const updatedShoppingCartLength = ( value ?: number) => {
        setShoppingCartLength( value !== undefined ? value : getShoppingCartLength())
    }   

    React.useEffect(()=>{

        auth.onAuthStateChanged( async ()=>{
            const loggedIn : boolean = isLoggedIn()
            if( loggedIn ){
                const token : string | undefined = await auth.currentUser?.getIdToken()
                if( token ) setToken(token)
            }
        })

    },[])



    
    


    return (
        <>
            <AuthManager noredirect setLoggedIn={setLoggedIn}/>
            <CobraLayout headerType="store" loggedIn={loggedIn} breadcrumb={breadcrumb} shoppingCartLength={shoppingCartLength}>
                <Checkout token={token} updatedShoppingCartLength={ updatedShoppingCartLength }/>
            </CobraLayout>
        </>
    )
}
export default PageContainer;


export const onTransactionSuccess = ( value : any, then ?: ( value : any ) => void  ) => {
    alert("success")
    setCheckoutProgress(getCheckoutSteps()["5"])
    if(then) then(value)
}

export const onTransactionFailure = ( value : any, then ?: ( value : any ) => void  ) => {
    alert("failure")
    setCheckoutProgress(getCheckoutSteps()["6"])
    if(then) then(value)
}

const Checkout = ( props: { token ?: string, updatedShoppingCartLength : ( value ?: number ) => void }) => {
    const [ subscribedToOrders, setSubscribedToOrders ] = React.useState<boolean>(false)

    if( ENABLE_ERROR_LOGGING ) console.log(getShoppingCart())

    const shippingOptions : any = getTestShippingOptions()
    
    const [ cart, setCart ] = useState<any>({})
    const [ shipping, setShipping ] = useState<any>(shippingOptions)
    const [ checkoutProgress, setLocalCheckoutProgress ] = useState(getCheckoutSteps()["1"])
   
    const [ email, setEmail ] = useState("nathanneuhaus@protonmail.com")
    const [ password, setPassword ] = useState("")
    const [ passwordVerify, setPasswordVerify ] = useState("")

    const [ firstNameShipping, setShippingFirstName ] = useState("")
    const [ lastNameShipping, setShippingLastName ] = useState("")
    const [ countryShipping, setShippingCountry ] = useState("US")
    const [ stateShipping, setShippingState ] = useState("")
    const [ addressShipping, setShippingAddress ] = useState("")
    const [ addressTwoShipping, setShippingAddressTwo ] = useState("") // "Address 2 (building, code...) - Optional"
    const [ zipcodeShipping, setShippingZipcode ] = useState("")
    const [ cityShipping, setShippingCity ] = useState("")

    const [ firstNameBilling, setBillingFirstName ] = useState("")
    const [ lastNameBilling, setBillingLastName ] = useState("")
    const [ countryBilling, setBillingCountry ] = useState("US")
    const [ stateBilling, setBillingState ] = useState("")
    const [ addressBilling, setBillingAddress ] = useState("")
    const [ addressTwoBilling, setBillingAddressTwo ] = useState("") // "Address 2 (building, code...) - Optional"
    const [ zipcodeBilling, setBillingZipcode ] = useState("")
    const [ cityBilling, setBillingCity ] = useState("")
    
    const [ company, setCompany ] = useState("")
    const [ phone, setPhone] = useState("")

    const [ loggedIn, setLoggedIn ] = useState(false)

    const userData = {
        email : email, 
        setEmail : setEmail, 
        password : password, 
        setPassword : setPassword, 
        passwordVerify : passwordVerify, 
        setPasswordVerify : setPasswordVerify, 
        firstNameShipping : firstNameShipping, 
        setShippingFirstName : setShippingFirstName, 
        lastNameShipping : lastNameShipping, 
        setShippingLastName : setShippingLastName, 
        countryShipping : countryShipping, 
        setShippingCountry : setShippingCountry, 
        stateShipping : stateShipping, 
        setShippingState : setShippingState, 
        addressShipping : addressShipping, 
        setShippingAddress : setShippingAddress, 
        addressTwoShipping : addressTwoShipping, 
        setShippingAddressTwo : setShippingAddressTwo, 
        zipcodeShipping : zipcodeShipping, 
        setShippingZipcode : setShippingZipcode, 
        cityShipping : cityShipping, 
        setShippingCity : setShippingCity, 
        firstNameBilling : firstNameBilling, 
        setBillingFirstName : setBillingFirstName, 
        lastNameBilling : lastNameBilling, 
        setBillingLastName : setBillingLastName, 
        countryBilling : countryBilling, 
        setBillingCountry : setBillingCountry, 
        stateBilling : stateBilling, 
        setBillingState : setBillingState, 
        addressBilling : addressBilling, 
        setBillingAddress : setBillingAddress, 
        addressTwoBilling : addressTwoBilling, 
        setBillingAddressTwo : setBillingAddressTwo, 
        zipcodeBilling : zipcodeBilling, 
        setBillingZipcode : setBillingZipcode, 
        cityBilling : cityBilling, 
        setBillingCity : setBillingCity, 
        company : company, 
        setCompany : setCompany, 
        phone : phone, 
        setPhone : setPhone, 
    }

    enum Checkout {
        PAYMENT_DECLINED = 5,
        PAYMENT_SUCCEDED = 6,
        PAYMENT_TIMEOUT = 7
    }

    React.useEffect(()=>{
        setCart(getShoppingCart())
        setLocalCheckoutProgress(getCheckoutProgress())
    },[]) 

    const linkStyle : Record<string,any> = {
        cursor : 'pointer', 
        color : FACEBOOK_BLACK,
        ':hover' : { color : '#1877F2'}, 
        ':active' : { color : FACEBOOK_SKYBLUE}
    }

    const on = ( value : number ) => getConfigDefaults().onMedia( value )
    
    if(getShoppingCartLength() === 0 ) return (
        <>
            <NoticeAndLink
                value={'Return to Store'}
                href={to(Page.HOME) }>
                    Your shopping Cart is empty.
            </NoticeAndLink>

        </>

    )

    return (
        <>  
            { getShoppingCartLength() === 0 && <NoticeAndLink
                value={'Return to Store'}
                href={to(Page.HOME) }>
                    Your shopping Cart is empty.
                </NoticeAndLink>
            }
            
            { checkoutProgress.step ===  Checkout.PAYMENT_SUCCEDED &&   <CheckoutStepSix {...userData}
                updatedShoppingCartLength={props.updatedShoppingCartLength}
                setCheckoutProgress={setLocalCheckoutProgress}
                />
            }

            { checkoutProgress.step ===  Checkout.PAYMENT_DECLINED &&   <CheckoutStepFive {...userData} checkoutProgress={checkoutProgress}
                setCheckoutProgress={()=>{
                    setCheckoutProgress(getCheckoutSteps()["4"])
                    setLocalCheckoutProgress(getCheckoutSteps()["4"])
                }}
                />
            }

            { checkoutProgress.step === Checkout.PAYMENT_TIMEOUT &&  <CheckoutStepTimeout {...userData} checkoutProgress={checkoutProgress}
                setCheckoutProgress={()=>{
                    setCheckoutProgress(getCheckoutSteps()["4"])
                    setLocalCheckoutProgress(getCheckoutSteps()["4"])
                }}
                />
            }

            { checkoutProgress.step < Checkout.PAYMENT_DECLINED &&  <>
            
                <LeftFrame>
                    <SpacerV h={40}/>
                    
                    <ProgressStepBar {...checkoutProgress} styles={{ container : { marginLeft : 50, maxWidth : 800 }}} goBack={()=>{
                        
                        let keyValue : string = String(checkoutProgress.step - 1)
                        if(checkoutProgress.step > 1 ){
                            if( checkoutProgress.step === 3 && isLoggedIn())
                                keyValue = String(checkoutProgress.step - 2)

                            const updateCheckoutProgress = (getCheckoutSteps() as any)[keyValue]
                            setCheckoutProgress(updateCheckoutProgress)
                            setLocalCheckoutProgress(updateCheckoutProgress)
                        }

                    }}/>

                    <SectionTitle>{checkoutProgress.title}</SectionTitle>
                    
                    { checkoutProgress.step === 1 && 
                        <CheckoutStepOne 
                            cart={cart} 
                            setCart={setCart} 
                            shipping={shipping}
                            setShipping={setShipping}
                            setCheckoutProgress={setLocalCheckoutProgress}
                            updatedShoppingCartLength={props.updatedShoppingCartLength}
                        />
                    }
                    
                    { checkoutProgress.step === 2 && 
                        
                        <CheckoutStepTwo {...userData}
                            //orders={orders}
                            //setOrders={setOrders}
                            setCheckoutProgress={setLocalCheckoutProgress}
                            
                        />
                        
                    }
                    
                    { checkoutProgress.step === 3 && 
                        <CheckoutStepThree token={props.token} userData={userData} {...userData}
                            setCheckoutProgress={setLocalCheckoutProgress}
                            
                        />
                    }
                    
                    { checkoutProgress.step === 4 && 
                        <CheckoutStepFour {...userData}
                            subscribedToOrders={subscribedToOrders}
                            setSubscribedToOrders={setSubscribedToOrders}
                            setCheckoutProgress={setLocalCheckoutProgress}
                            updatedShoppingCartLength={props.updatedShoppingCartLength}
                        />
                    }
                </LeftFrame>
  
                <RightFrame>
                    {(()=>{
                        const config : GlobalConfig = validateConfig()
                        const onBackmarketBreakpoint = config.onMedia(991)
                        return <img {...css({ 
                                cursor : 'pointer', 
                                position : 'absolute', 
                                marginLeft : 280,
                                marginTop : 36,
                                [onBackmarketBreakpoint] : {
                                    marginLeft : 'calc(100% - 216px)',
                                } 
                            })} 
                            src={StripeBadge} 
                            width={150} 
                            height={50}
                            onClick={()=>{ 
                                
                                //
                            }} 
                        />

                    })()}
                    
                    <SectionTitle>Summary</SectionTitle>
                    
                    
                    
                    <CheckoutWrapperWhiteBG>
                        
                        { 
                            Object.keys(cart).map(( keyValue : string, index : number )=>{
                                const item = cart[ keyValue ]
                
                                return (
                                    <div key={getRandomString()}>
                                        <SummaryItem 
                                            url={item.img}
                                            title={item.name}
                                            desc={item.desc}
                                            price={(item.quantity * item.price)}
                                            quantity={item.quantity}
                                        />
                                        {   
                                            (()=>{

                                                const subscriptionPlan = item.subscription &&
                                                item.subscription.plan && 
                                                item.subscription[item.subscription.plan].quantity > 0  
                                                    ? item.subscription[item.subscription.plan]
                                                    : undefined

                                                if( subscriptionPlan )
                                                    return <SummaryItem 
                                                        url={subscriptionPlan.img}
                                                        title={subscriptionPlan.name}
                                                        desc={subscriptionPlan.desc}
                                                        price={(subscriptionPlan.quantity * subscriptionPlan.price)}
                                                        quantity={subscriptionPlan.quantity}
                                                    />

                                            })()
                                        }
                                        {   
                                            (()=>{

                                                const protectionPlan = item.protection && 
                                                item.protection.plan && 
                                                item.protection[item.protection.plan].quantity > 0  
                                                    ? item.protection[item.protection.plan]
                                                    : undefined

                                                if(protectionPlan) 
                                                    return <SummaryItem 
                                                    url={protectionPlan.img}
                                                    title={protectionPlan.name}
                                                    desc={protectionPlan.desc}
                                                    price={(protectionPlan.quantity * protectionPlan.price)}
                                                    quantity={protectionPlan.quantity}
                                                />

                                            })()
                                        }

                                    </div>
                                )
                            })
                        }

                        <div style={{ marginLeft : -5 }}>
                        {
                            Object.keys(cart).length > 0 && <>
                                    <SpacerV h={20}/>
                                <LineDivider/>
                                
                                <LineDivider/>
                                <SummaryItem 
                                    title={`Subtotal`}
                                    price={getSubtotal(cart)}
                                />

                                <SummaryItem 
                                    title={`Tax`}
                                    price={getTax(cart)}
                                />

                                {
                                    shipping && <SummaryItem 
                                        title={`Shipping`}
                                        price={shipping[shipping.plan].price}
                                    />
                                }
                                
                                

                                <SummaryItem 
                                    title={`Total`}
                                    price={getTotal(cart, shipping )}
                                />
                                
                                
                                { checkoutProgress.step === 1 && 
                                    <CheckoutButtonBig onClick={()=>{
                                        
                                        if( ENABLE_ERROR_LOGGING ) console.log(cart)
                                        const checkoutProgress = getCheckoutSteps()["2"]
                                        setLocalCheckoutProgress(checkoutProgress)
                                        setCheckoutProgress(checkoutProgress)
                                    }}>
                                        Checkout
                                    </CheckoutButtonBig>
                                }

                                <SpacerV  h={30}/>
                            </>

                        }
                        </div>
                        

                    </CheckoutWrapperWhiteBG>
                </RightFrame>
                
                <ToastContainer hideProgressBar autoClose={2000} position="bottom-right"/>
                    
            </>}
            
          
          
        </>
    )
}
