import React from "react"
import { getCheckoutSteps } from "./shared"
import { retrieveSuccessfulOrders, setCheckoutProgress } from "../../service/checkout-progress"
import SpacerV from "../../locust/sections/SpacerV"
import { SectionTitle } from "./CheckoutSectionTitle"
import X from "../../locust/composables/X"
import { css } from "glamor"
import { setShoppingCart } from "../../service/shopping-cart"
import { OrderDataModel } from "../orders/Models"
import OrderSubscription from "../orders/OrderSubscription"
import { toDateString } from "../orders/Shared"
import NoticeAndLink from "../shared/NoticeAndLink"
import { capitalize, getRandomString } from "../../locust/composables/Shared"
import { Page, to } from "../shared/SiteLinks"
import { LoaderFullScreen } from "../shared/LoaderFullScreen"
import OrderProduct from "../orders/OrderProduct"
import { ENABLE_ERROR_LOGGING } from "../../settings/project-settings"

// setCheckoutProgress({})
// setShoppingCart({})

const CheckoutStepSix = ( props : { 
    setCheckoutProgress : ( checkoutProgress : any ) => void,
    updatedShoppingCartLength : ( value ?: number ) => void 
}) => {

    const [ data, setData ] = React.useState<any>(false)
    
    const resetCheckoutAndCart = () => {
        setCheckoutProgress(getCheckoutSteps()["1"])
        setShoppingCart(undefined)
    }

    React.useEffect(()=>{
        const successfullOrders : any = retrieveSuccessfulOrders()
        resetCheckoutAndCart()
        if( ENABLE_ERROR_LOGGING ) console.log("successfullOrders: ...")
        if( ENABLE_ERROR_LOGGING ) console.log(successfullOrders)

        //alert(successfullOrders)
        setData(successfullOrders)

        // auth.onAuthStateChanged(()=>{
        //     const loggedIn : boolean = isLoggedIn()
        //     if( loggedIn ){

        //         const data : any = localStorage.getItem('checkoutSuccess');
        //         if( ENABLE_ERROR_LOGGING )  console.log(JSON.parse(data))
        //         // getOrdersFromRemote({ 
        //         //     onSuccess : ( response : any ) => {
        //         //         //props.updatedShoppingCartLength(0)
        //         //         //
        //         //     }}
        //         // )
        //     } 
        // })

        //alert()
    },[])

    

    let validOrderNumbers : any = {}
    
    // getCheckoutSuccess().map(( orderData : any )=>{
    //     validOrderNumbers = {
    //         ...validOrderNumbers,
    //         [orderData.orderNumber] : true
    //     }
    // })


    if( data === false ) return (
        <LoaderFullScreen/>
    )

    if( data !== false ) {
        setCheckoutProgress({})
        setShoppingCart({})
        
        
    }



    return (
        <>  

            
                    <SpacerV h={50}/>
                    <X>
                        <SectionTitle style={{ width : '100%', marginTop : -20}}>
                            <p style={{ fontSize : 36, fontWeight : 800 }}>
                                
                            </p>
                        </SectionTitle>
                    </X>    

                    <X>
                        <div {...css({
                            width : '100%',
                            marginLeft : 8,
                            maxWidth : 800,
                        })}>
                            
                                
                                
                            
                                { Object.keys(data).map(( keyValue : string, index : number )=>{

                                    const orderItem : any = data[keyValue]
                                    const type : any = orderItem.type
                                    const isProduct : boolean = type === 'product'

                                    // if( !validOrderNumbers[ orderData.orderNumber ])
                                    //     return <div key={ getRandomString() }/>
                                    //return <div key={index}>{JSON.stringify(orderItem)}</div>


                                    if( isProduct ){    
                                        if( ENABLE_ERROR_LOGGING )  console.log('product...')       
                                        const productData : OrderDataModel = {
                                            id: orderItem.order.desc,
                                            shipping: {
                                                addressShipping: "",
                                                addressShippingTwo: "",
                                                firstNameShipping: "",
                                                lastNameShipping: "",
                                                cityShipping: "",
                                                stateShipping: "",
                                                zipcodeShipping: ""
                                            },
                                            date: toDateString(orderItem.created),
                                            name: orderItem.order.name,
                                            product: orderItem.order.id,
                                            total: String(Number(orderItem.amount)/100)
                                        }
                                        // was OrderProduct
                                        return <OrderProduct orderData={productData} key={getRandomString()}/>
                                    }

                                    const isSubscription : boolean = type === 'subscription'
                                    if( isSubscription ){
                                        
                                        const subscriptionData : OrderDataModel = {
                                            id: "subscription",
                                            shipping: {
                                                addressShipping: "",
                                                addressShippingTwo: "",
                                                firstNameShipping: "",
                                                lastNameShipping: "",
                                                cityShipping: "",
                                                stateShipping: "",
                                                zipcodeShipping: ""
                                            },
                                            status : orderItem.status,
                                            date: toDateString(orderItem.created),
                                            name: capitalize(orderItem.subtype),
                                            product: "",
                                            total: String(Number(orderItem.amount)/100)
                                        }
                                        return <OrderSubscription 
                                            type={orderItem.subtype} 
                                            orderData={subscriptionData} 
                                            key={getRandomString()}
                                            subscription={{ 
                                                start : orderItem.subscription.current_period_start, 
                                                end : orderItem.subscription.current_period_end, 
                                            }}
                                        />
                                    }   
                                    
                                    
                                })}
                                
                            
                        
                        </div>
                    </X>
                    
                    <NoticeAndLink
                        value={'Return to Store'}
                        href={to(Page.HOME)}>
                            We humbly thank you for your business.
                    </NoticeAndLink>
            

        </>
    )
}

export default CheckoutStepSix;




