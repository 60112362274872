import { FormControlLabel, Radio, RadioGroup } from "@mui/material"
import React from "react"
import { LineDivider } from "../../locust/components/LineDivider"
import { RadioBox } from "../../locust/components/Payment"
import SpacerV from "../../locust/sections/SpacerV"
import { CheckoutWrapperWhiteBG } from "./shared"
import { setShippingMethod, setShoppingCart } from "../../service/shopping-cart"
import { CartItem } from "./CartItem"
import { CartItemTitleDescPrice } from "./CartItemTitleDescPrice"
import { CheckoutCartItemBox } from "./CheckoutCartItemBox"
import { TitleAndPrice } from "./TitleAndPrice"
import { ENABLE_ERROR_LOGGING } from "../../settings/project-settings"

const CheckoutStepOne = ( props : { 
    cart : any, 
    setCart : ( cart : any ) => void, 
    setCheckoutProgress : ( checkoutProgress : any ) => void,
    shipping : any, 
    setShipping : ( shippingPlan : any ) => void, 
    updatedShoppingCartLength : () => void,
}) => {

    const cart = props.cart
    const setCart = props.setCart

    const CART_HAS_SHIPPABLE_ITEMS = JSON.stringify(cart)!=="{}"  
        && Object.keys( cart ).map( x => cart[x] ).filter( x => x.type === 'product' ).length > 0 

    const setShipping = ( shipping : string ) => {
        props.setShipping( shipping )
        setShippingMethod( shipping )
    }

    return (
        <>
            <CheckoutWrapperWhiteBG>
                <CheckoutCartItemBox>

                    
                    { Object.keys(cart).length > 0 && Object.keys(cart).map(( keyValue : string, index : number  )=>{
                            const item = (cart as any)[keyValue]
                         
                            return (
                                <div key={index}>  
                                    { 
                                        index > 0 && 
                                            <LineDivider style={{ marginTop : 15, marginBottom : 15  }}/> 
                                    }
                                    <CartItem 
                                            updatedShoppingCartLength={props.updatedShoppingCartLength}
                                            item={{ [keyValue] : item }}
                                            img={item.img}  
                                            quantity={ item.quantity } 
                                            setQuantity={( value : number ) => {
                                                let updatedItem = { ...item, quantity : value }
                                                let updatedCart = { ...cart, [ keyValue ] : updatedItem }
                                                setCart(updatedCart)
                                                setShoppingCart(updatedCart)
                                        }}
                                        subscription={item.subscription}
                                        setSubscription={( value : string )=>{
                                                let updatedSubscription = { ...item.subscription, plan : value}
                                                let updatedItem = { ...item, subscription : updatedSubscription}
                                                let updatedCart = { ...cart, [ keyValue ] : updatedItem }
                                                setCart(updatedCart)
                                                setShoppingCart(updatedCart)
                                        }}
                                        protection={item.protection}
                                        setProtectionPlan={( value : string )=>{
                                                let updatedProtectionPlan = { ...item.protection, plan : value }
                                                let updatedItem = { ...item, protection : updatedProtectionPlan }
                                                let updatedCart = { ...cart, [ keyValue ] : updatedItem }
                                                setCart(updatedCart) 
                                                setShoppingCart(updatedCart)
                                        }}
                                        setProtectionPlanQuantity={( value : number )=>{
                                                let planKey = item.protection.plan
                                                let updatedCurrentProtectionPlan = {...item.protection[planKey], quantity : value }
                                                let updatedProtection = { ...item.protection, [planKey] : updatedCurrentProtectionPlan }
                                                let updatedItem = { ...item, protection : updatedProtection }
                                                let updatedCart = { ...cart, [ keyValue ] : updatedItem }
                                                setCart(updatedCart)
                                                setShoppingCart(updatedCart)
                                        }}
                                        setSubscriptionPlanQuantity={( value : number )=>{
                                                
                                                let planKey = item.subscription.plan
                                                let updatedCurrentSubscriptionPlan = {...item.subscription[planKey], quantity : value }
                                                let updatedSubscription = { ...item.subscription, [planKey] : updatedCurrentSubscriptionPlan }
                                                let updatedItem = { ...item, subscription : updatedSubscription }
                                                let updatedCart = { ...cart, [ keyValue ] : updatedItem }
                                                setCart(updatedCart)
                                                setShoppingCart(updatedCart)
                                        }}
                                        setPromotion={( promotion : any )=>{

                                            let promotionKey = 'promotion'
                                            let updatedSubscription = {
                                                ...item.subscription,
                                                [promotionKey] : promotion,
                                                plan : promotionKey,
                                            }

                                            if( ENABLE_ERROR_LOGGING ) console.log(updatedSubscription)
                                            let updatedItem = { ...item, subscription : updatedSubscription }
                                            let updatedCart = { ...cart, [ keyValue ] : updatedItem }
                                            setCart(updatedCart)
                                            setShoppingCart(updatedCart)
                                        }}
                                        onClickRemove={()=>{
                                                let updatedCart = {}
                                                Object.keys(cart).forEach(( subKeyValue )=>{
                                                    if(subKeyValue === keyValue) return
                                                    updatedCart = { ...updatedCart, [subKeyValue] : cart[subKeyValue] }
                                                    setCart(updatedCart)
                                                })
                                                setCart(updatedCart)
                                                setShoppingCart(updatedCart)
                                        }}
                                        >
                                        <CartItemTitleDescPrice
                                            name={item.name}
                                            price={`$${item.price}`}
                                        />
                                    </CartItem>

                                    

                                </div>
                            )
                        })
                    }
                </CheckoutCartItemBox>
                </CheckoutWrapperWhiteBG>
                { CART_HAS_SHIPPABLE_ITEMS && <>
                    <CheckoutWrapperWhiteBG>
                
                        <CheckoutCartItemBox>
                        
                                
                                <SpacerV h={24}/> 
                                <TitleAndPrice price={props.shipping[props.shipping.plan].price} title={`Shipping Options`}/>
                                <SpacerV h={14}/> 

                                <RadioGroup value={ props.shipping.plan }>      
                                    {
                                        (()=>{

                                            const style : Record<string,any> = {
                                                width : 'calc(100% - 15px) ', 
                                                marginLeft : 0, 
                                                height : 'auto' 
                                            }

                                            return Object.keys(props.shipping).map(( keyValue : string, index : number )=>{
                                                if( keyValue === 'plan') return
                                                const shippingPlan = props.shipping[keyValue]
                                                return (
                                                    <RadioBox
                                                        key={index} 
                                                        style={style}  
                                                        onClick={()=>{ 
                                                            let updatedShipping = {
                                                                ...props.shipping,
                                                                plan : keyValue
                                                            }
                                                            setShipping(updatedShipping)
                                                        }}
                                                    >
                                                        <FormControlLabel value={ keyValue } control={ <Radio/> } label={ shippingPlan.name  } />
                                                        <SpacerV h={8}/>  
                                                    </RadioBox> 
                                                )
                                            })
                                        })()
                                    }
                                    

                                </RadioGroup>
                            
                        </CheckoutCartItemBox>
                
                    </CheckoutWrapperWhiteBG>
                </>}
        
        </>


    )

}

export default CheckoutStepOne;