import { css } from "glamor"
import React from "react"
import { BadgeNumbered } from "../../locust/components/BadgeNumbered"
import { GlobalConfig, validateConfig } from "../../locust/composables/Layout"
import SpacerV from "../../locust/sections/SpacerV"
import { ItemPrice, OrderItemDesc, OrderItemTitle } from "./Typography"

export const SummaryItem = ( props : { 
    children ?: any, 
    quantity ?: number, 
    url ?: string, 
    price : number, 
    title : string, 
    desc ?: string 
}) => {

const imgSize : number = props.url !== undefined ? 40 : 0
const config : GlobalConfig = validateConfig()
const padding : number = 20 
const priceColumnWidth : number = 80
const titleBoxPadding : number = 10 
const minHeight : number = 0
const quantity : number = props.quantity ? props.quantity : 0
const priceFormatted : string = Number(props.price).toFixed(2)

return (
    <div {...css({ width : `calc(100% - ${padding}px)`, minHeight : minHeight, height : 'auto', padding : 20, paddingBottom : 0, backgroundColor : '', })}>

        <div {...css({ 
            verticalAlign : 'top',
            height : '100%', 
            minHeight : imgSize, 
            width: imgSize, 
            display : 'inline-block', 
            //backgroundColor : 'yellow',
            backgroundSize : '100%',
            backgroundPosition : '50% top',
            backgroundRepeat : 'no-repeat',
            backgroundImage : `url(${props.url})`,
        })}>
            { quantity > 1 && 
                <BadgeNumbered style={{ position : 'absolute', marginTop : 0, marginLeft : -5 }}>
                    {quantity}
                </BadgeNumbered> 
            }
        </div>
        
        <div {...css({  
            verticalAlign : 'top',
            height : '100%', 
            width : `calc(100% - ${imgSize}px - ${padding}px - ${priceColumnWidth}px - ${titleBoxPadding}px)`,
            display : 'inline-block', 
            //backgroundColor : 'red',
            paddingLeft : titleBoxPadding
        })}>
            <SpacerV h={6}/>
            <OrderItemTitle>
                {props.title}
            </OrderItemTitle>
            { props.desc && 
                <OrderItemDesc>
                    {props.desc}
                </OrderItemDesc> 
            }
            
        </div>

        <div {...css({  
            verticalAlign : 'top',
            height : '100%', 
            
            width : priceColumnWidth,
            display : 'inline-block', 
            //backgroundColor : 'orange',
        })}>
            <div style={{ width : '100%', textAlign : 'right'}}>
                <SpacerV h={6}/>
                <ItemPrice>
                    {priceFormatted}
                </ItemPrice>
            </div>
        </div>
    </div>
)
}