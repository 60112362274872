import { Button } from "@mui/material"
import { css } from "glamor"
import React from "react"
import { getConfigDefaults, GlobalConfig } from "../../locust/composables/Layout"
import LinkDelayed from "../../locust/composables/LinkDelayed"
import X from "../../locust/composables/X"
import SpacerV from "../../locust/sections/SpacerV"
import { getProductParamsByURL } from "../products/Shared"
import EntryItemText from "../shared/EntryItemText"
import { to, Page } from "../shared/SiteLinks"
import { OrderDataModel } from "./Models"
import OrderProductItem from "./OrderProductItem"
import OrdersInfoBox from "./OrdersInfoBox"

export default ( props :{ orderData : OrderDataModel }) => {

    const height = 'auto'
    const productData : any =  getProductParamsByURL('icy-inferno')
    const globals : GlobalConfig = getConfigDefaults()
    const onBreakOne : string = globals.onMedia(500)
    const onBreakTwo : string = globals.onMedia(300)
    
    const thumbnail = {
        verticalAlign : 'top',
        width : 200, 
        display : 'inline-block',
        backgroundColor : '',
        minHeight : 100,
        height : '100%',
        [onBreakOne]:{ 
            backgroundColor : '',
            width : '100%',
        }, 
        
    }
    const product = {
        verticalAlign : 'top',
        marginLeft: -20,
        width : `calc(100% - ${180}px)`, 
        display : 'inline-block',
        minHeight : 100,
        backgroundColor : '',
        [onBreakOne]:{ 
            backgroundColor : '',
            width : '100%',
            marginLeft : 10 
        }, 
        
    }
    
    return (
        <OrdersInfoBox style={{ 
            height : height, 
            paddingBottom : 20, 
            width : 'calc(100% - 45px)', 
            maxWidth : '',
            [onBreakTwo] : {
                width : 360 - 60,
            }
        }}>
            
                <div style={{ height : 40, width : '100%', borderBottom : '0.5px solid rgba(0,0,0,0.1)'}}>
                        <SpacerV h={8}/>
                        <EntryItemText style={{ fontWeight : 400 }}><X>{props.orderData.name}</X></EntryItemText>
                        
                </div>
                
                <div {...css({  ...thumbnail })}>
                    <SpacerV h={20}/>
                        
                    <LinkDelayed to={`${to(Page.PRODUCTS)}/${props.orderData.product}`}>
                        <X>
                        <Button style={{ marginLeft : 0 }}>
                            <div>
                            <div style={{ display : 'block'}}>
                                <img 
                                    style={{
                                        marginTop : 0, 
                                        borderRadius : 5, 
                                        boxShadow: `rgba(0, 0, 0, 0.15) 0px 5px 15px`  
                                    }} 
                                    width={150} 
                                    src={productData.images.back}
                                />
                            </div>
                            <div style={{ display : 'block'}}>
                                <img 
                                    style={{
                                        display : 'block',
                                        marginTop : 0, 
                                        borderRadius : 5, 
                                        boxShadow: `rgba(0, 0, 0, 0.15) 0px 5px 15px`  
                                    }} 
                                    width={150} 
                                    src={productData.images.front}
                                />
                            </div>
                            </div>
                            
                        </Button>
                        </X>
                    </LinkDelayed>
                        
                </div>
               


                <div {...css({ ...product })}>
                    
                    <div {...css({ marginLeft : 25, width : 'calc(100% - 60px)', height : 'auto', backgroundColor : ''})}>
                        
                        <OrderProductItem orderData={props.orderData}/>  

 
                    </div>
                    
                </div>
            
        </OrdersInfoBox>
    )
}