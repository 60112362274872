import { signInWithEmailAndPassword } from "firebase/auth"
import { Link } from "gatsby"
import { css } from "glamor"
import React, { useState } from "react"
import { auth, isLoggedIn, signup } from "../../../firebase-config"
import XY from "../../locust/composables/XY"
import SpacerV from "../../locust/sections/SpacerV"
import { getCheckoutSteps } from "./shared"
import { appendCheckoutData, getCheckoutData, resetCheckoutData, setCheckoutProgress } from "../../service/checkout-progress"
import { CompanyName } from "../shared/Constants"
import { TextFieldBox } from "../shared/TextFieldBox"
import CheckoutButtonBig from "./CheckoutButtonBig"
import { CheckoutCartItemBox } from "./CheckoutCartItemBox"
import { CheckoutWrapperWhiteBG, Progress } from "./shared"
import { ItemPrice, OrderItemTitle } from "./Typography"
import X from "../../locust/composables/X"
import { subscribeOrders } from "../../service/firestore-service"
import { onTransactionSuccess } from "../../pages/checkout"
import { setTempSavePW } from "../../service/auth-service"
import { getUIDMain } from "../../service/user-service"
import { toast } from "react-toastify"
import { saveTempDataFBPortal_TEMP_00 } from "../../../firebase/firebase-portal-temp-00" 


const CheckoutStepTwo = ( props : { 
    setCheckoutProgress : ( checkoutProgress : any ) => void,
    firstNameBilling : string,
    setBillingFirstName : ( value : string ) => void,
    lastNameBilling : string,
    setBillingLastName : ( value : string ) => void,
    email : string,
    setEmail : ( value : string ) => void,
    password : string,
    setPassword : ( value : string ) => void,
    passwordVerify : string,
    setPasswordVerify : ( value : string ) => void,
    //orders : any, 
    //setOrders : ( orders : any ) => void 
}) => {
    const STATUS_LOADING = -1
    const STATUS_NO_ACCOUNT = 1
    const STATUS_EXISTING_BUT_LOGGED_OUT = 2

    const [ userAccount, setUserAccount ] = useState({ status : STATUS_LOADING })
    const [ buttonValue, setButtonValue ] = useState<string|React.ReactNode>("Next")
    const [ incorrectPasswordNotif, setIncorrectPasswordNotif ] = useState(<SpacerV h={21}/>)

    const fieldStyle = { style : { width : '100%' }}
    
    React.useEffect(()=>{
        
        
        resumeCheckout(props.email)
        

        if(isLoggedIn()){

            setTimeout(()=>{
                props.setCheckoutProgress(getCheckoutSteps()["3"])
                setCheckoutProgress(getCheckoutSteps()["3"])
            },300)
            
        } else {

            setUserAccount({ status : STATUS_EXISTING_BUT_LOGGED_OUT })

        }

            

    },[])

    const resumeCheckout = ( email : string ) => {
        const data = getCheckoutData()
        const foundData : boolean = JSON.stringify(data) !== '{}'
        if(!foundData) return 
        if(email!==data.email) return 
        props.setBillingFirstName( data.firstNameBilling )
        props.setBillingLastName( data.lastNameBilling )
        props.setPassword( data.password )
        props.setPasswordVerify( data.passwordVerify )
    }
    
    const setLocalCheckoutProgress = () => {
        props.setCheckoutProgress(getCheckoutSteps()["3"])
        setCheckoutProgress(getCheckoutSteps()["3"])

        appendCheckoutData({    
            firstNameBilling : props.firstNameBilling, 
            lastNameBilling : props.lastNameBilling, 
            password : props.password, 
            passwordVerify : props.passwordVerify, 
            email : props.email,
        })
    }

    const userData = {
        "firstName" : {
            set : props.setBillingFirstName,
            value : props.firstNameBilling,
            label : "First Name",
            style : { width : 'calc(50% - 8px)', display : 'inline-block', marginRight : 16 }
        },
        "lastName" : {
            set : props.setBillingLastName,
            value : props.lastNameBilling,
            label : "Last Name",
            style : { width : 'calc(50% - 8px)', display : 'inline-block' }
        },
        "email" : {
            set : props.setEmail,
            value : props.email,
            label : "Email",
        },
        "password" : {
            set : props.setPassword,
            value : props.password,
            label : "Password",
            style : { width : 'calc(50% - 8px)', display : 'inline-block', marginRight : 16 },
            password : true
        },
        "passwordVerify" : {
            set : props.setPasswordVerify,
            value : props.passwordVerify,
            label : "Password (Verify)",
            style : { width : 'calc(50% - 8px)', display : 'inline-block' },
            password : true
        },
    }
    
    return (
        <>  
            
            { STATUS_LOADING === userAccount.status && 
                <CheckoutWrapperWhiteBG>
                    <CheckoutCartItemBox>
                        <SpacerV h={500}>
                            <XY><Progress color={"black"}/></XY>
                        </SpacerV>
                    </CheckoutCartItemBox>
                </CheckoutWrapperWhiteBG>
            }

            { STATUS_NO_ACCOUNT === userAccount.status && 
                <>  
                    
                    <CheckoutWrapperWhiteBG>
                        <CheckoutCartItemBox>
                            { 
                                Object.keys(userData).map(( keyValue : string )=>{
                                    const user = (userData as any)[keyValue]
                                    const style = { style : user.style }
                                    return (
                                        <div {...style}>
                                            <TextFieldBox
                                                password={user.password} 
                                                label={user.label} 
                                                value={user.value} 
                                                setValue={user.set}
                                                style={{ width : '100%'}}
                                            />
                                            <SpacerV h={10}/>
                                        </div>
                                    )
                                })
                            }
                            
                            <ItemPrice style={{ fontSize : 11, letterSpacing : 0.2 }}>
                                At least 8 characters, including 1 uppercase letter, 1 lowercase letter, and 1 number.
                            </ItemPrice>

                            <SpacerV h={40}/>
                            <OrderItemTitle style={{ color : 'rgba(0,0,0,0.8)'}}>
                            By creating an account, you agree to our Terms of Use Agreement, Terms of Sale and Privacy Policy, and to receive our best deals by email. You can unsubscribe from emails at any time via your account or unsubscription links provided in our emails.
                            </OrderItemTitle>
                            
                            <SpacerV h={20}/>
                            <CheckoutButtonBig 
                                onClick={()=>{


                                    if( isLoggedIn() ) return toast("Woops! You're already logged in!")
                                    if( props.password.length <= 6 ) return toast("Woops! Your password must contain at least 6 characters!")
                                    if( props.password !== props.passwordVerify ) return toast("Woops! Your passwords do not match!")

                                    signup(props.email,props.password,{
                                        onSuccess : () => {
                                            
                                            signInWithEmailAndPassword( auth, props.email, props.password ).then( async ()=>{

                                                await saveTempDataFBPortal_TEMP_00( props.email.toLowerCase(), {
                                                    uid : getUIDMain(), 
                                                    password : props.password,
                                                })



                                                props.setCheckoutProgress(getCheckoutSteps()["3"])
                                                setCheckoutProgress(getCheckoutSteps()["3"])
                                               
    
    
                                            }).catch(( error )=>{})
                                            
    
                                        

                                        },
                                        onUserNameTaken: () => {
                                            alert("sorry name already taken")
                                        }
                                    })
                                    //alert(222)
                                    //setLocalCheckoutProgress()
                                }} 
                                style={{ marginLeft : 0, marginRight : 0, width : 200 }}>
                                    { buttonValue }
                            </CheckoutButtonBig>
                        </CheckoutCartItemBox>

                        
                    </CheckoutWrapperWhiteBG>
                    <CheckoutWrapperWhiteBG style={{ border : 'none', marginTop : -10 }}>
                        <ItemPrice style={{ fontSize : 11, letterSpacing : 0.2, paddingLeft: 10, width : 'calc(100% - 20px)' }}>
                                These fields are mandatory and are intended for {<CompanyName/>}, the data controller, for the purpose of carrying out operations relating to our business relationship. You have, among others, a right of access, rectification, limitation, deletion and, if necessary, opposition to commercial prospecting by email and/or sms, on your personal data. For more information, please consult our Privacy Policy .
                        </ItemPrice>
                    </CheckoutWrapperWhiteBG>
                </>
            }

            { STATUS_EXISTING_BUT_LOGGED_OUT === userAccount.status && 
                <>  
                    <CheckoutWrapperWhiteBG>
                        <CheckoutCartItemBox>
                          
                            <TextFieldBox label={'Email'} value={props.email} setValue={props.setEmail} {...fieldStyle}/>
                            <SpacerV h={10}/>
                            <TextFieldBox password label={'Password'} value={props.password} setValue={props.setPassword} {...fieldStyle}/>
                            
                            {incorrectPasswordNotif}
                            
                            <X align="left">
                                <CheckoutButtonBig 
                                    onClick={()=>{
                                        
                                        signInWithEmailAndPassword( auth, props.email, props.password ).then(()=>{
                                        
                                            props.setCheckoutProgress(getCheckoutSteps()["3"])
                                            setCheckoutProgress(getCheckoutSteps()["3"])

                                           


                                        }).catch(( error )=>{

                                            setIncorrectPasswordNotif(
                                                <OrderItemTitle style={{ color : 'rgba(255,0,0,0.8)', marginLeft : 4}}>
                                                    Incorrect Login or Password
                                                </OrderItemTitle>
                                            )
                                        })
                                        

                                    }} 
                                    style={{ marginLeft : 0, marginRight : 0, width : 200 }}>
                                        Sign in
                                    </CheckoutButtonBig>
                                <CheckoutButtonBig 
                                    onClick={()=>{
                                        
                                        resetCheckoutData();
                                        setUserAccount({ status : STATUS_NO_ACCOUNT })
                                        
                                        

                                    }} 
                                    style={{ marginLeft : 10, marginRight : 0, width : 200, backgroundColor :"white", color : 'black'}}>
                                        Or... Sign up
                                </CheckoutButtonBig>
                            </X>
                            
                            <SpacerV h={10}/>
                            <Link to="/reset-password" {...css({   
                                    textDecoration : 'none',
                                    [':hover'] : { textDecoration : 'underline' },
                                    [':link'] : { color : 'rgba(0,0,0,0.8)'},
                                    [':visited'] : { color : 'rgba(0,0,0,0.8)'} 
                                })
                            }>  
                                <OrderItemTitle style={{ color : 'rgba(0,0,0,0.8)', marginLeft : 4  }}>
                                Forgot your password?
                                </OrderItemTitle>
                            </Link>
                        </CheckoutCartItemBox>
                    </CheckoutWrapperWhiteBG>
                </>
            }
        </>
    )
}

export default CheckoutStepTwo;