import React, { useState } from "react"
import { CheckoutWrapperWhiteBG } from "./shared"
import { getCheckoutError } from "../../service/checkout-progress"
import SpacerV from "../../locust/sections/SpacerV"
import { SectionTitle } from "./CheckoutSectionTitle"
import X from "../../locust/composables/X"
import { css } from "glamor"
import XY from "../../locust/composables/XY"
import { SpacerVRsp } from "../../locust/composables/SpacerVRsp"
import { Link } from "gatsby"
import { CartItemTitle } from "./CartItemTitle"
import { FACEBOOK_BLACK, FACEBOOK_SKYBLUE } from "../../assets/colors/Colors"
import { CircularProgress } from "@mui/material"
import NoticeAndLink from "../shared/NoticeAndLink"
import { Page, to } from "../shared/SiteLinks"

// [TO-DO] checkout -> capture the sale information from a failed purchasae and submit a high-severity form to 
// yourself and contact them and ask them if they would like to try again. 


const CheckoutStepFive = ( props : { 
    setCheckoutProgress : ( ) => void,
    checkoutProgress : any,
}) => {
    const checkoutProgress : any = props.checkoutProgress
    

    const [ data, setData ] = React.useState<any>(false)

    React.useEffect(()=>{
        setData(getCheckoutError())
    },[])


    if( data === false ) return (
        <div style={{ width : '100%', height : '70vh'}}>
            <XY>
                <CircularProgress style={{ color : FACEBOOK_BLACK, marginTop : 35 }}/>
            </XY>
        </div>
    )

    

    const isError : boolean = data.response === 'error'

    return (
        <>  
            <SpacerV h={50}/>
            <X>
                <SectionTitle style={{ width : '100%', marginTop : -20}}>
                    <p style={{ fontSize : 36, fontWeight : 800 }}>
                        { isError ? 'Unknown Error' : 'Card Declined'}
                    </p>
                </SectionTitle>
            </X>    
            
            <NoticeAndLink
                onClick={()=>{ 
                    if(!isError){
                        props.setCheckoutProgress()

                    }
                 }}
                
                href={ isError ? to(Page.HOME) : to(Page.CHECKOUT) }
                value={ isError ? "Return to Store" : "Try Different Card?"}
            >
                { isError 
                    ? <>The card ending in **** was declined. Try again? </> 
                    : <div style={{ width : 300 }}>Card declined.</div>
                }


                
            </NoticeAndLink>
            
        </>
    )
}

export default CheckoutStepFive;




const ReturnToStore = ( props : { children ?: any  }) => {

    return 
    const linkStyle : Record<string,any> = {
        cursor : 'pointer', 
        color : FACEBOOK_BLACK,
        ':hover' : { color : '#1877F2'}, 
        ':active' : { color : FACEBOOK_SKYBLUE}
    }
    
    return (
        <>
            <X>
                <div {...css({ 
                    
                    width : 'calc(100% - 60px)',
                    maxWidth : 760,

                })}>
                <CheckoutWrapperWhiteBG style={{ paddingLeft : 20, paddingRight : 20, borderRadius: 6  }}>
                    <XY>
                        
                        <SpacerV h={20}/>
                        <SpacerVRsp breakpoint={351} before={15} after={30}/>
                        
                        <SectionTitle style={{ width : '100%', marginTop : -30, backgroundColor : ''}}>
                            <p style={{ fontSize : 16, fontWeight : 800, textAlign : 'center' }}>
                                
                                {props.children}
                            </p>
                        </SectionTitle> 
                    
                    
                        <SpacerV h={20}/>
                        <Link to={to(Page.HOME)} {...css({ 
                            position: 'absolute', marginTop : 50, fontSize : 14, ':link' : { textDecoration : 'none'}})}>
                            <CartItemTitle style={{ marginTop : - 20, ...linkStyle }}>Return to Store</CartItemTitle>
                        </Link>
                    
                    </XY>
                </CheckoutWrapperWhiteBG>
                </div>
            </X>

        </>
    )
}