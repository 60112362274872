import { Checkbox, FormControlLabel, FormGroup } from "@mui/material"
import React, { useState } from "react"
import { isLoggedInAndRegistered } from "../../../firebase-config"
import { Popup, PopupSlide } from "../../locust/components/PopupSlide"
import XY from "../../locust/composables/XY"
import SpacerV from "../../locust/sections/SpacerV"
import { CheckoutWrapperWhiteBG, getCheckoutSteps } from "./shared"
import { appendCheckoutData, getCheckoutData, setCheckoutProgress } from "../../service/checkout-progress"
import { TextFieldBox } from "../shared/TextFieldBox"
import CheckoutButtonBig from "./CheckoutButtonBig"
import { CheckoutCartItemBox } from "./CheckoutCartItemBox"
import { CheckoutConstants, getCustomersFromRemote, Progress} from "./shared"
import { BillingInfo, ShippingInfo } from "../shared/BliingShippingInfo"
import { trimSafe } from "../../locust/composables/Shared"
import { getOrdersFS } from "../../service/firestore-service"
import { getShoppingCart, getShoppingCartLength } from "../../service/shopping-cart"
import { ENABLE_ERROR_LOGGING } from "../../settings/project-settings"

// [TO-DO] ASCERTAIN IF CURRENT IMPLEMENTATION (ON BOTH MOBILE AND WEBAPP) NO LONGER USES PASSWORD ? (state.password, localstorage.password, remote.password)

//[TO-DO]: tests 
// test-1: has no in-state data
// test-2: has no localstoreate data 
// test-3: has no remote data
// test-4: has no membership



const STATUS_LOADING = -1
const STATUS_HAS_SHIPPING_AND_BILLING = 0
const STATUS_ADD_BILLING = 1

const isDataSufficient = ( storageData : any, sameShippingAddress : boolean ) => 
    !!storageData.firstNameBilling && 
    !!storageData.lastNameBilling  && 
    !!storageData.addressBilling  &&
    !!storageData.cityBilling  &&
    !!storageData.stateBilling  &&
    !!storageData.zipcodeBilling &&
    ( sameShippingAddress || (
        !sameShippingAddress && 
        !!storageData.firstNameShipping && 
        !!storageData.lastNameShipping  && 
        !!storageData.addressShipping  &&
        !!storageData.cityShipping  &&
        !!storageData.stateShipping  &&
        !!storageData.zipcodeShipping
    )) 

const CheckoutStepThree = ( props : {

    token ?: string,
    userData : any,  
    email : string,
    setCheckoutProgress : ( checkoutProgress : any ) => void,
    password : string,
    setPassword : ( value : string ) => void,
    firstNameShipping : string, 
    setShippingFirstName : ( value : string ) => void,
    lastNameShipping : string, 
    setShippingLastName : ( value : string ) => void,
    countryShipping : string, 
    setShippingCountry : ( value : string ) => void,
    addressShipping : string, 
    setShippingAddress : ( value : string ) => void,
    addressTwoShipping : string, 
    setShippingAddressTwo : ( value : string ) => void,
    cityShipping : string, 
    setShippingCity : ( value : string ) => void,
    stateShipping : string, 
    setShippingState : ( value : string ) => void,
    zipcodeShipping : string, 
    setShippingZipcode : ( value : string ) => void,
    firstNameBilling : string, 
    setBillingFirstName : ( value : string ) => void,
    lastNameBilling : string, 
    setBillingLastName : ( value : string ) => void,
    countryBilling : string, 
    setBillingCountry : ( value : string ) => void,
    addressBilling : string, 
    setBillingAddress : ( value : string ) => void,
    addressTwoBilling : string, 
    setBillingAddressTwo : ( value : string ) => void,
    cityBilling : string, 
    setBillingCity : ( value : string ) => void,
    stateBilling : string, 
    setBillingState : ( value : string ) => void,
    zipcodeBilling : string, 
    setBillingZipcode : ( value : string ) => void,

    phone : string, 
    setPhone : ( value : string ) => void,
}) => {

    const [ userAccount, setUserAccount ] = useState({ status : STATUS_LOADING })
    const [ sameShippingAddress, setSameShippingAdddress] = useState(true)
    const [ buttonValue, setButtonValue ] = useState<string|React.ReactNode>("Next")
    const [ popup, setPopup ] = useState<Popup>({ toggle : false, title : "" })

    const [firstnameBillingRequiredError, setFirstnameBillingRequiredError] = useState(false)
    const [lastnameBillingRequiredError, setLastnameBillingRequiredError] = useState(false)
    const [addressBillingRequiredError, setAddressBillingRequiredError] = useState(false)
    const [cityBillingRequiredError, setCityBillingRequiredError] = useState(false)
    const [stateBillingRequiredError, setStateBillingRequiredError] = useState(false)
    const [zipcodeBillingRequiredError, setZipcodeBillingRequiredError] = useState(false)
    const [firstnameShippingRequiredError, setFirstnameShippingRequiredError] = useState(false)
    const [lastnameShippingRequiredError, setLastnameShippingRequiredError] = useState(false)
    const [addressShippingRequiredError, setAddressShippingRequiredError] = useState(false)
    const [cityShippingRequiredError, setCityShippingRequiredError] = useState(false)
    const [stateShippingRequiredError, setStateShippingRequiredError] = useState(false)
    const [zipcodeShippingRequiredError, setZipcodeShippingRequiredError] = useState(false)
    
    const [phoneRequiredError, setPhoneRequiredError] = useState(false)

    React.useEffect(()=>{
        
        (async()=>{

            if( ENABLE_ERROR_LOGGING ) console.log("! - ....")
            if( ENABLE_ERROR_LOGGING ) console.log(props)

            // [IMPLEMENTATION-INFO-UPDATED]:
            // 0. do I need to try to load from props (state) first ? 
            // 1. will attempt to load data from local-storage
            // 2. next will check if logged in and registered, then load from database
            // 3. will not load any data and force user to enter data


            // 1. will attempt to load data from state
            const storageData               : any       = resumeCheckout(props.email)
            const foundUserDataInStorage    : boolean   = storageData !== false
            const hasSufficentStorageData   : boolean   = foundUserDataInStorage && isDataSufficient(storageData, sameShippingAddress )
            if( ENABLE_ERROR_LOGGING ) console.log(`! - foundUserDataInStorage: `  + foundUserDataInStorage )
            if( ENABLE_ERROR_LOGGING ) console.log(`! - hasSufficentStorageData: ` + hasSufficentStorageData )
            if( hasSufficentStorageData ) return setUserAccount({ status : STATUS_HAS_SHIPPING_AND_BILLING })


            // 2. if logged in and registered load data from database
            let hasSufficentRemoteData : boolean = false
            if( isLoggedInAndRegistered()) await getOrdersFS({
                onSuccess : ( remoteData : any ) => {
                    const customer = remoteData.customer
                    if(!customer) {
                        if( ENABLE_ERROR_LOGGING ) console.log("! - No remote data found for customer"); 
                        return ;
                    }
                    hasSufficentRemoteData = isDataSufficient( customer, sameShippingAddress )
                    if( ENABLE_ERROR_LOGGING ) console.log(`! - foundUserDataInRemote: ` + true )
                    if( ENABLE_ERROR_LOGGING ) console.log(`! - hasSufficentREmoteData: ` + hasSufficentRemoteData )
                    
                    // if remote data is not blank, and it differs from data state in state, set state to remote value
                    if( customer.phone              && customer.phone               !== props.phone )               props.setPhone( customer.phone )
                    if( customer.firstNameBilling   && customer.firstNameBilling    !== props.firstNameBilling )    props.setBillingFirstName(customer.firstNameBilling )
                    if( customer.lastNameBilling    && customer.lastNameBilling     !== props.lastNameBilling )     props.setBillingLastName( customer.lastNameBilling )
                    if( customer.countryBilling     && customer.countryBilling      !== props.countryBilling )      props.setBillingCountry( customer.countryBilling )
                    if( customer.addressBilling     && customer.addressBilling      !== props.addressBilling )      props.setBillingAddress( customer.addressBilling )
                    if( customer.addressTwoBilling  && customer.addressTwoBilling   !== props.addressTwoBilling )   props.setBillingAddressTwo( customer.addressTwoBilling )
                    if( customer.cityBilling        && customer.cityBilling         !== props.cityBilling )         props.setBillingCity( customer.cityBilling )
                    if( customer.stateBilling       && customer.stateBilling        !== props.stateBilling )        props.setBillingState( customer.stateBilling )
                    if( customer.zipcodeBilling     && customer.zipcodeBilling      !== props.zipcodeBilling )      props.setBillingZipcode( customer.zipcodeBilling )
                    if( customer.firstNameShipping  && customer.firstNameShipping   !== props.firstNameShipping )   props.setShippingFirstName(customer.firstNameShipping )
                    if( customer.lastNameShipping   && customer.lastNameShipping    !== props.lastNameShipping )    props.setShippingLastName( customer.lastNameShipping )
                    if( customer.countryShipping    && customer.countryShipping     !== props.countryShipping )     props.setShippingCountry( customer.countryShipping )
                    if( customer.addressShipping    && customer.addressShipping     !== props.addressShipping )     props.setShippingAddress( customer.addressShipping )
                    if( customer.addressTwoShipping && customer.addressTwoShipping  !== props.addressTwoShipping )  props.setShippingAddressTwo( customer.addressTwoShipping )
                    if( customer.cityShipping       && customer.cityShipping        !== props.cityShipping )        props.setShippingCity( customer.cityShipping )
                    if( customer.stateShipping      && customer.stateShipping       !== props.stateShipping )       props.setShippingState( customer.stateShipping )
                    if( customer.zipcodeShipping    && customer.zipcodeShipping     !== props.zipcodeShipping )     props.setShippingZipcode( customer.zipcodeShipping )
                }
            })
            if( hasSufficentRemoteData ) return setUserAccount({ status : STATUS_HAS_SHIPPING_AND_BILLING })
            
            // 3. don't load any data, force user to enter new data 
            return setUserAccount({ status : STATUS_ADD_BILLING })
            
        })()
        
    },[])
    
    const resumeCheckout = ( email : string ) => {
        
        //[TO-DO][SECURITY] : ensure that the users password is not retained in storage
        const storage = getCheckoutData()
        const foundData : boolean = JSON.stringify(storage) !== '{}'
        if(!foundData){ if( ENABLE_ERROR_LOGGING ) console.log("! - no data found in state"); return false; }
        if( email!==storage.email ){ if( ENABLE_ERROR_LOGGING ) console.log("! - current user has no matching data in state"); return false; }  
        if( ENABLE_ERROR_LOGGING ) console.log("! - successfully found matching data in storage for current user")
        
        // if data in storage is not blank, and it differs from data state in state, set state to storage value
        if( storage.phone              && storage.phone               !== props.phone )               props.setPhone( storage.phone )
        if( storage.firstNameBilling   && storage.firstNameBilling    !== props.firstNameBilling )    props.setBillingFirstName(storage.firstNameBilling )
        if( storage.lastNameBilling    && storage.lastNameBilling     !== props.lastNameBilling )     props.setBillingLastName( storage.lastNameBilling )
        if( storage.countryBilling     && storage.countryBilling      !== props.countryBilling )      props.setBillingCountry( storage.countryBilling )
        if( storage.addressBilling     && storage.addressBilling      !== props.addressBilling )      props.setBillingAddress( storage.addressBilling )
        if( storage.addressTwoBilling  && storage.addressTwoBilling   !== props.addressTwoBilling )   props.setBillingAddressTwo( storage.addressTwoBilling )
        if( storage.cityBilling        && storage.cityBilling         !== props.cityBilling )         props.setBillingCity( storage.cityBilling )
        if( storage.stateBilling       && storage.stateBilling        !== props.stateBilling )        props.setBillingState( storage.stateBilling )
        if( storage.zipcodeBilling     && storage.zipcodeBilling      !== props.zipcodeBilling )      props.setBillingZipcode( storage.zipcodeBilling )
        if( storage.firstNameShipping  && storage.firstNameShipping   !== props.firstNameShipping )   props.setShippingFirstName(storage.firstNameShipping )
        if( storage.lastNameShipping   && storage.lastNameShipping    !== props.lastNameShipping )    props.setShippingLastName( storage.lastNameShipping )
        if( storage.countryShipping    && storage.countryShipping     !== props.countryShipping )     props.setShippingCountry( storage.countryShipping )
        if( storage.addressShipping    && storage.addressShipping     !== props.addressShipping )     props.setShippingAddress( storage.addressShipping )
        if( storage.addressTwoShipping && storage.addressTwoShipping  !== props.addressTwoShipping )  props.setShippingAddressTwo( storage.addressTwoShipping )
        if( storage.cityShipping       && storage.cityShipping        !== props.cityShipping )        props.setShippingCity( storage.cityShipping )
        if( storage.stateShipping      && storage.stateShipping       !== props.stateShipping )       props.setShippingState( storage.stateShipping )
        if( storage.zipcodeShipping    && storage.zipcodeShipping     !== props.zipcodeShipping )     props.setShippingZipcode( storage.zipcodeShipping )
        
        //if( storage.password           && storage.password            !== props.password )            props.setPassword( storage.password )
        return storage
    }

    const billingData = {
        "firstName" : {
            set : props.setBillingFirstName,
            value : props.firstNameBilling,
            label : "First Name",
            style : { width : 'calc(50% - 8px)', display : 'inline-block', marginRight : 16 }
        },
        "lastName" : {
            set : props.setBillingLastName,
            value : props.lastNameBilling,
            label : "Last Name",
            style : { width : 'calc(50% - 8px)', display : 'inline-block' }
        },
        /*
        "country" : {
            set : props.setBillingCountry,
            value : props.countryBilling,
            label : "Country"
        },
        */
        "address" : {
            set : props.setBillingAddress,
            value : props.addressBilling,
            label : "Address"
        },
        "addressTwo" : {
            set : props.setBillingAddressTwo,
            value : props.addressTwoBilling,
            label : "Address 2 (building, code...)"
        },
        "city" : {
            set : props.setBillingCity,
            value : props.cityBilling,
            label : "City",
            style : { width : 'calc(50% - 8px)', display : 'inline-block', marginRight : 16 }
        },
        "state" : {
            set : props.setBillingState,
            value : props.stateBilling,
            label : "State/province/region",
            style : { width : 'calc(50% - 8px)', display : 'inline-block' }
        },
        "zipcode" : {
            set : props.setBillingZipcode,
            value : props.zipcodeBilling,
            label : "Zipcode",
            style : { width : 'calc(50% - 8px)', display : 'inline-block', marginRight : 16 }
        },
       
        "phone" : {
            set : props.setPhone,
            value : props.phone,
            label : "Phone",
            style : { width : 'calc(50% - 8px)', display : 'inline-block' }
        }
    }

    const shippingData = {
        "firstName" : {
            set : props.setShippingFirstName,
            value : props.firstNameShipping,
            label : "First Name",
            style : { width : 'calc(50% - 8px)', display : 'inline-block', marginRight : 16 }
        },
        "lastName" : {
            set : props.setShippingLastName,
            value : props.lastNameShipping,
            label : "Last Name",
            style : { width : 'calc(50% - 8px)', display : 'inline-block' }
        },
        /*
        "country" : {
            set : props.setShippingCountry,
            value : props.countryShipping,
            label : "Country",
        },
        */
        "address" : {
            set : props.setShippingAddress,
            value : props.addressShipping,
            label : "Address"
        },
        "addressTwo" : {
            set : props.setShippingAddressTwo,
            value : props.addressTwoShipping,
            label : "Address 2 (building, code...)"
        },
        "city" : {
            set : props.setShippingCity,
            value : props.cityShipping,
            label : "City",
            style : { width : 'calc(50% - 8px)', display : 'inline-block', marginRight : 16 }
        },
        "state" : {
            set : props.setShippingState,
            value : props.stateShipping,
            label : "State/province/region",
            style : { width : 'calc(50% - 8px)', display : 'inline-block' }
        },
        "zipcode" : {
            set : props.setShippingZipcode,
            value : props.zipcodeShipping,
            label : "Zipcode",
            style : { width : 'calc(50% - 8px)', display : 'inline-block', marginRight : 16 }
        },
        "phone" : {
            set : props.setPhone,
            value : props.phone,
            label : "Phone",
            style : { width : 'calc(50% - 8px)', display : 'inline-block' }
        }
    }

    const setLocalCheckoutProgress = ( data : any ) => {
        props.setCheckoutProgress(getCheckoutSteps()["4"])
        setCheckoutProgress(getCheckoutSteps()["4"])
        appendCheckoutData(data)
    }

    const containsShippableItems = ( shoppingCart : any ) => {
        if( ENABLE_ERROR_LOGGING ) console.log(shoppingCart)
        const products = Object.keys(shoppingCart).filter( x => shoppingCart[x].type === "product")
        return products.length > 0 
    }

    return (
        <>  
            { STATUS_LOADING === userAccount.status && 
                <CheckoutWrapperWhiteBG>
                    <CheckoutCartItemBox>
                        <SpacerV h={500}>
                            <XY><Progress color={"black"}/></XY>
                        </SpacerV>
                    </CheckoutCartItemBox>
                </CheckoutWrapperWhiteBG>
            }

            { STATUS_HAS_SHIPPING_AND_BILLING === userAccount.status && 
                <>
                    
                    <CheckoutWrapperWhiteBG>
                        <CheckoutCartItemBox>
                            {/*<TextFieldBox label={'Email'} value={email} setValue={setEmail} {...fieldStyle}/>*/}
                            
                            <BillingInfo  {...props.userData} setPopup={setPopup} onClick={()=>{
                                setUserAccount({ status : STATUS_ADD_BILLING })
                            }}/>

                            { containsShippableItems( getShoppingCart() ) &&
                                <ShippingInfo {...props.userData} setPopup={setPopup} onClick={()=>{
                                    setUserAccount({ status : STATUS_ADD_BILLING })
                                }}/>
                            }
                            
                            <CheckoutButtonBig 
                                style={{ marginLeft : 0, marginRight : 0, width : 200 }}
                                onClick={()=>{ 
                                    setButtonValue(<Progress/>)
                                        setTimeout(()=>{
                                            setLocalCheckoutProgress({
                                                email               : props.email,
                                                password            : props.password,
                                                phone               : props.phone, 

                                                firstNameBilling    : props.firstNameBilling, 
                                                lastNameBilling     : props.lastNameBilling, 
                                                countryBilling      : props.countryBilling, 
                                                addressBilling      : props.addressBilling, 
                                                addressTwoBilling   : props.addressTwoBilling, 
                                                cityBilling         : props.cityBilling, 
                                                stateBilling        : props.stateBilling, 

                                                // firstNameShipping   : props.firstNameShipping ? props.firstNameShipping : props.firstNameBilling, 
                                                // lastNameShipping    : props.lastNameShipping ? props.lastNameShipping : props.lastNameBilling,  
                                                // addressShipping     : props.addressShipping ? props.addressShipping : props.addressBilling, 
                                                // addressTwoShipping  : props.addressTwoShipping ? props.addressTwoShipping : props.addressTwoBilling, 
                                                // cityShipping        : props.cityShipping ? props.cityShipping : props.cityBilling, 
                                                // stateShipping       : props.stateShipping ? props.stateShipping : props.stateBilling, 
                                                // zipcodeShipping     : props.zipcodeShipping ? props.zipcodeShipping : props.zipcodeBilling, 
                                                
                                            })
                                            setButtonValue('Next')
                                        }, CheckoutConstants.MINIMUM_TIMEOUT )

                                }} 
                            >   { buttonValue }
                            </CheckoutButtonBig>
                        </CheckoutCartItemBox>
                    </CheckoutWrapperWhiteBG>
                    <PopupSlide popup={popup} setPopup={setPopup} title="Title here"/>
                </>
            }

            { STATUS_ADD_BILLING === userAccount.status && 
                <>  
                    <CheckoutWrapperWhiteBG>
                        <CheckoutCartItemBox>
                        
                            { Object.keys(billingData).map(( keyValue : string )=>{
                                const billing = (billingData as any)[keyValue]
                                const style = { style : billing.style }
                                return (
                                    <div {...style}>
                                        <TextFieldBox 
                                            label={billing.label} 
                                            value={billing.value} 
                                            setValue={billing.set}
                                            emptyFieldError={(()=>{
                                                
                                                if( billing.label==="First Name" )              return firstnameBillingRequiredError
                                                if( billing.label==="Last Name" )               return lastnameBillingRequiredError
                                                if( billing.label==="Address" )                 return addressBillingRequiredError
                                                if( billing.label==="City" )                    return cityBillingRequiredError
                                                if( billing.label==="State/province/region" )   return stateBillingRequiredError
                                                if( billing.label==="Zipcode" )                 return zipcodeBillingRequiredError
                                                if( billing.label==="Phone" )                   return phoneRequiredError
                                                return false

                                            })()}
                                            style={{ width : '100%'}}
                                        />
                                        <SpacerV h={10}/>
                                    </div>

                                )
                            })}

                            { containsShippableItems( getShoppingCart() ) &&
                                <FormGroup>
                                <FormControlLabel 
                                    label="Ship to Same Address" 
                                    control={
                                        <Checkbox 
                                            checked={sameShippingAddress}
                                            onChange={()=>{
                                                setSameShippingAdddress(!sameShippingAddress)
                                            }} 
                                            sx={{
                                                color: 'black',
                                                '&.Mui-checked': {
                                                  color: 'black',
                                                },
                                              }}
                                        />
                                    } 
                                />
                                </FormGroup>
                            }

                            


                            { !sameShippingAddress && Object.keys(shippingData).map(( keyValue : string )=>{
                                const shipping = (shippingData as any)[keyValue]
                                const style = { style : shipping.style }
                                return (
                                    <div {...style}>
                                        <TextFieldBox 
                                            label={shipping.label} 
                                            value={shipping.value} 
                                            setValue={shipping.set}
                                            style={{ width : '100%'}}
                                            emptyFieldError={(()=>{
                                                
                                                if( shipping.label==="First Name" )              return firstnameShippingRequiredError
                                                if( shipping.label==="Last Name" )               return lastnameShippingRequiredError
                                                if( shipping.label==="Address" )                 return addressShippingRequiredError
                                                if( shipping.label==="City" )                    return cityShippingRequiredError
                                                if( shipping.label==="State/province/region" )   return stateShippingRequiredError
                                                if( shipping.label==="Zipcode" )                 return zipcodeShippingRequiredError
                                                if( shipping.label==="Phone" )                   return phoneRequiredError
                                                return false

                                            })()}
                                        />
                                        <SpacerV h={10}/>
                                    </div>

                                )
                            })}

                            <CheckoutButtonBig 
                                style={{ float : 'right', marginLeft : 0, marginBottom : 30, marginRight : 0, width : 200 }}
                                onClick={()=>{
                                    
                                
                                    setButtonValue(<Progress/>)
                                    setTimeout(()=>{
                                            

                                        const accountData = {
                                            email               : props.email,
                                            password            : props.password,
                                            phone               : props.phone, 

                                            firstNameBilling    : props.firstNameBilling, 
                                            lastNameBilling     : props.lastNameBilling,
                                            addressBilling      : props.addressBilling, 
                                            addressTwoBilling   : props.addressTwoBilling, 
                                            cityBilling         : props.cityBilling, 
                                            stateBilling        : props.stateBilling, 
                                            zipcodeBilling      : props.zipcodeBilling, 
                                            countryBilling      : props.countryBilling,  

                                            firstNameShipping   : !sameShippingAddress ? props.firstNameShipping    : props.firstNameBilling, 
                                            lastNameShipping    : !sameShippingAddress ? props.lastNameShipping     : props.lastNameBilling, 
                                            addressShipping     : !sameShippingAddress ? props.addressShipping      : props.addressBilling, 
                                            addressTwoShipping  : !sameShippingAddress ? props.addressTwoShipping   : props.addressTwoBilling, 
                                            cityShipping        : !sameShippingAddress ? props.cityShipping         : props.cityBilling, 
                                            stateShipping       : !sameShippingAddress ? props.stateShipping        : props.stateBilling, 
                                            zipcodeShipping     : !sameShippingAddress ? props.zipcodeShipping      : props.zipcodeBilling, 
                                            countryShipping     : !sameShippingAddress ? props.countryShipping      : props.countryBilling, 
                                        }
                                                    
                                        const firstnameBillingLengthError   = trimSafe(props.firstNameBilling).length < 2
                                        const lastnameBillingLengthError    = trimSafe(props.lastNameBilling).length < 2
                                        const addressBillingLengthError     = trimSafe(props.addressBilling).length < 5
                                        const cityBillingLengthError        = trimSafe(props.cityBilling).length < 3
                                        const stateBillingLengthError       = trimSafe(props.stateBilling).length < 2 
                                        const zipcodeBillingLengthError     = trimSafe(props.zipcodeBilling).length < 5 
                                        const phoneLengthError              = trimSafe(props.phone).length < 10

                                        const firstnameShippingLengthError   = !sameShippingAddress && trimSafe(props.firstNameShipping).length < 2
                                        const lastnameShippingLengthError    = !sameShippingAddress && trimSafe(props.lastNameShipping).length < 2
                                        const addressShippingLengthError     = !sameShippingAddress && trimSafe(props.addressShipping).length < 5
                                        const cityShippingLengthError        = !sameShippingAddress && trimSafe(props.cityShipping).length < 3
                                        const stateShippingLengthError       = !sameShippingAddress && trimSafe(props.stateShipping).length < 2 
                                        const zipcodeShippingLengthError     = !sameShippingAddress && trimSafe(props.zipcodeShipping).length < 5 

                                        const isFormIncompleteError : boolean = phoneLengthError || 
                                            firstnameBillingLengthError ||
                                            lastnameBillingLengthError ||
                                            addressBillingLengthError ||
                                            cityBillingLengthError ||
                                            stateBillingLengthError ||
                                            zipcodeBillingLengthError ||
                                            firstnameShippingLengthError ||
                                            lastnameShippingLengthError ||
                                            addressShippingLengthError ||
                                            cityShippingLengthError ||
                                            stateShippingLengthError ||
                                            zipcodeShippingLengthError

                                        setPhoneRequiredError(phoneLengthError)
                                        setFirstnameBillingRequiredError(firstnameBillingLengthError)
                                        setLastnameBillingRequiredError(lastnameBillingLengthError)
                                        setAddressBillingRequiredError(addressBillingLengthError)                                        
                                        setCityBillingRequiredError(cityBillingLengthError)
                                        setStateBillingRequiredError(stateBillingLengthError)
                                        setZipcodeBillingRequiredError(zipcodeBillingLengthError)

                                        setFirstnameShippingRequiredError(firstnameShippingLengthError)
                                        setLastnameShippingRequiredError(lastnameShippingLengthError)
                                        setAddressShippingRequiredError(addressShippingLengthError)                                        
                                        setCityShippingRequiredError(cityShippingLengthError)
                                        setStateShippingRequiredError(stateShippingLengthError)
                                        setZipcodeShippingRequiredError(zipcodeShippingLengthError)
                                    
                                        if( isFormIncompleteError ) return setButtonValue('Next')

                                        // if(!props.firstNameShipping ) props.setShippingAddress(props.firstNameBilling)
                                        // if(!props.lastNameShipping ) props.setShippingAddress(props.lastNameBilling)
                                        // if(!props.addressShipping ) props.setShippingAddress(props.addressBilling)
                                        // if(!props.addressTwoShipping ) props.setShippingAddress(props.addressTwoBilling)
                                        // if(!props.cityShipping ) props.setShippingAddress(props.cityBilling)
                                        // if(!props.stateShipping ) props.setShippingAddress(props.stateBilling)
                                        // if(!props.zipcodeShipping ) props.setShippingAddress(props.zipcodeBilling)
                                        // if(!props.countryShipping ) props.setShippingAddress(props.countryBilling)

                                        appendCheckoutData(accountData)
                                        setUserAccount({ status : STATUS_HAS_SHIPPING_AND_BILLING })
                                        setButtonValue('Next')

                                    }, CheckoutConstants.MINIMUM_TIMEOUT )
                                    return
                                }}>
                                    { buttonValue }
                            </CheckoutButtonBig>
                            
                            
                            
                        </CheckoutCartItemBox>

                        
                    </CheckoutWrapperWhiteBG>
                    
                </>
            }
        </>
    )
}

export default CheckoutStepThree;