import React from "react"
import { FACEBOOK_BLACK, FACEBOOK_SKYBLUE } from "../../assets/colors/Colors"
import { BorderedBox } from "../../locust/components/BorderedBox"
import { Popup } from "../../locust/components/PopupSlide"
import { SectionTitle } from "../checkout/CheckoutSectionTitle"
import EditIcon from '@mui/icons-material/Edit';
import { OrderItemTitle } from "../checkout/Typography"
import X from "../../locust/composables/X"
import XY from "../../locust/composables/XY"
import { css } from "glamor"


export const ShippingInfo = ( props : { 
    setPopup : ( props : Popup ) => void, 
    style ?: Record<string,any>
    email : string,
    
    firstNameShipping : string, 
    setShippingFirstName : ( value : string ) => void,
    lastNameShipping : string, 
    setShippingLastName : ( value : string ) => void,
    countryShipping : string, 
    setShippingCountry : ( value : string ) => void,
    addressShipping : string, 
    setShippingAddress : ( value : string ) => void,
    addressTwoShipping : string, 
    setShippingAddressTwo : ( value : string ) => void,
    cityShipping : string, 
    setShippingCity : ( value : string ) => void,
    stateShipping : string, 
    setShippingState : ( value : string ) => void,
    zipcodeShipping : string, 
    setShippingZipcode : ( value : string ) => void,

    phone : string, 
    setPhone : ( value : string ) => void,

    onClick ?: () => void 
} ) => {

    const height : number = 190
    
    const iconStyle : Record<string,any> = {
        cursor : 'pointer', 
        color : FACEBOOK_BLACK,
        ':hover' : { color : '#1877F2'}, 
        ':active' : { color : FACEBOOK_SKYBLUE}
    }

    return (
        <InfoBox style={{ height : height, ...props.style }}>
            <X>
                <div style={{ width : `calc(100% - ${60}px)`, backgroundColor : '', height : 'auto'}}>
                    <SectionTitle style={{ marginTop : 0, height : 'auto', fontSize : 16  }}>
                        Shipping Info
                    </SectionTitle>
                    <div style={{ marginLeft : 25, marginTop : -16  }}>
                        <OrderItemTitle style={{ marginTop : 6 }}>
                            {`${props.firstNameShipping} ${props.lastNameShipping}`}
                        </OrderItemTitle>
                        <OrderItemTitle style={{ marginTop : 6 }}>
                            {props.addressShipping}
                        </OrderItemTitle>
                        <OrderItemTitle style={{ marginTop : 6 }}>
                            {props.cityShipping}
                        </OrderItemTitle>
                        <OrderItemTitle style={{ marginTop : 6 }}>
                            {props.stateShipping}
                        </OrderItemTitle>
                        <OrderItemTitle style={{ marginTop : 6 }}>
                            {props.zipcodeShipping}
                        </OrderItemTitle>
                        
                        
                    </div>
                </div>
                <div 
                    {...css({...iconStyle, width : 60 })}
                    onClick={ props.onClick ? props.onClick : ()=>{ 
                        props.setPopup({ 
                            toggle : true, 
                            title : 'Shipping Info',
                            fields : {
                                "First Name" : {},
                                "Last Name" : {},
                                "Street Address" : {},   
                                "Address 2 (building, code...) - Optional" : {},
                                "City" : {},
                                "State" : {},
                                "Zip" : {},
                                "Country" : {},
                            } 
                        })   
                    }}>
                    <XY>
                        <EditIcon/>
                    </XY>
                </div>
            </X>
        </InfoBox>
    )
}

export const BillingInfo = ( props : { 
    setPopup : ( props : Popup ) => void, 
    style ?: Record<string,any>
    firstNameBilling : string, 
    setBillingFirstName : ( value : string ) => void,
    lastNameBilling : string, 
    setBillingLastName : ( value : string ) => void,
    countryBilling : string, 
    setBillingCountry : ( value : string ) => void,
    addressBilling : string, 
    setBillingAddress : ( value : string ) => void,
    addressTwoBilling : string, 
    setBillingAddressTwo : ( value : string ) => void,
    cityBilling : string, 
    setBillingCity : ( value : string ) => void,
    stateBilling : string, 
    setBillingState : ( value : string ) => void,
    zipcodeBilling : string, 
    setBillingZipcode : ( value : string ) => void,
    phone : string, 
    setPhone : ( value : string ) => void,

    firstNameShipping : string, 
    lastNameShipping : string, 
    countryShipping : string, 
    addressShipping : string, 
    addressTwoShipping : string, 
    cityShipping : string, 
    stateShipping : string, 
    zipcodeShipping : string, 

    onClick ?: () => void 
    
} ) => {

    const height : number = 190
    
    const iconStyle : Record<string,any> = {
        cursor : 'pointer', 
        color : FACEBOOK_BLACK,
        ':hover' : { color : '#1877F2'}, 
        ':active' : { color : FACEBOOK_SKYBLUE}
    }

    return (
        <InfoBox style={{ height : height, ...props.style }}>
            <X>
                <div style={{ width : `calc(100% - ${60}px)`, backgroundColor : '', height : 'auto'}}>
                    <SectionTitle style={{ marginTop : 0, height : 'auto', fontSize : 16  }}>
                        Billing Info
                    </SectionTitle>
                    <div style={{ marginLeft : 25, marginTop : -16  }}>
                        <OrderItemTitle style={{ marginTop : 6 }}>
                            {`${props.firstNameBilling ? props.firstNameBilling : props.firstNameShipping} ${props.lastNameBilling ? props.lastNameBilling : props.lastNameShipping}`}
                        </OrderItemTitle>
                        <OrderItemTitle style={{ marginTop : 6 }}>
                            {props.addressBilling ? props.addressBilling : props.addressShipping }
                        </OrderItemTitle>
                        <OrderItemTitle style={{ marginTop : 6 }}>
                            {props.cityBilling ? props.cityBilling : props.cityShipping }
                        </OrderItemTitle>
                        <OrderItemTitle style={{ marginTop : 6 }}>
                            {props.stateBilling ? props.stateBilling : props.stateShipping }
                        </OrderItemTitle>
                        <OrderItemTitle style={{ marginTop : 6 }}>
                            {props.zipcodeBilling ? props.zipcodeBilling : props.zipcodeShipping }
                        </OrderItemTitle>  
                    </div>
                </div>
                <div 
                    {...css({...iconStyle, width : 60 })}
                    onClick={ props.onClick ? props.onClick : ()=>{ 
                        props.setPopup({ 
                            toggle : true, 
                            title : 'Billing Info',
                            fields : {
                                "First Name" : {},
                                "Last Name" : {},
                                "Street Address" : {},   
                                "Address 2 (building, code...) - Optional" : {},
                                "City" : {},
                                "State" : {},
                                "Zip" : {},
                                "Country" : {},
                            } 
                        })   
                    }}>
                    <XY>
                        <EditIcon/>
                    </XY>
                </div>
            </X>
            
        </InfoBox>
    )
}




const InfoBox = ( props : { children ?: any, style ?: Record<string,any> }) => {
    return (
        <BorderedBox 
                style={{ 
                    maxWidth : 400,
                    width : '100%',
                    //padding : 20,
                    borderRadius : 4,
                    margin: 20,
                    marginLeft : 0,
                    ...props.style
                }
            }>    
            { props.children }
        </BorderedBox>
    )
}