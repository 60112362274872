import React from 'react'
import { CartItemTitle } from './CartItemTitle'


export const TitleAndPrice = (props : { title : string, price ?: number, children ?: any, style ?: Record<string,any> }) => {
    return (
        <div style={{ width : '100%', height : 'auto', marginBottom : -10, ...props.style }}>
            <div style={{ width : `calc(100% - ${65}px)`, display : 'inline-block'}}>
                <CartItemTitle>{props.title}</CartItemTitle>
            </div>
            <div style={{ width : 50, display : 'inline-block', textAlign : 'right', marginRight : 15 }}>
                
                { props.price !== undefined && 
                    <CartItemTitle>{'$'+(props.price > 0 ? props.price : '0.00')}</CartItemTitle>
                }

            </div>
        </div>
    )
}