import React from 'react'
import { CartItemTitle } from './CartItemTitle'
import { CartItemDesc, ItemPrice } from './Typography'

export const CartItemTitleDescPrice = ( props : { name : string, price : string, desc ?: string }) => {
    return <div>
        <div style={{ height : 'auto', marginLeft : 10}}>
            <CartItemTitle>
              {props.name}
            </CartItemTitle>
        </div>
        { props.desc && 
            <div style={{ height : 'auto', marginLeft : 10 }}>
                <CartItemDesc>
                    { props.desc }
                </CartItemDesc>
            </div>
        }
        <div style={{ height : 'auto', marginLeft : 10 }}>
            <ItemPrice>
                {props.price}
            </ItemPrice>
        </div>
    </div>
}